import {
  AggregateFunctionOption,
  CustomFunctionOption,
  DbtDataField,
  DbtTable,
  Field,
  FieldOutput,
  Filter,
  FilterOperatorOption,
  FilterRelativeDateValueUnitOption,
  IntervalOption,
  JoinMap,
  LookupDto,
  QueryRequest,
  QueryResult,
  QueryTotalResult,
  TableJoinStrategy,
} from "api/Api";
import { DataType } from "api/Api-extension";
import { Box, DataTypeIcon, Highlighter, Stack, Tooltip, Typography } from "components";
import { ReactNode, createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { deepCopy } from "services";
import apiService from "services/apiService";
import { theme } from "theme";
import { andyDefaultQueryRequest } from "./DefaultQueryRequest/andyDefaultQueryRequest";
import { defaultQueryRequest } from "./DefaultQueryRequest/defaultQueryRequest";
import { getArrayFromTableTree } from "./utils/table-tree-utils";
import { getFieldFullIdentifier } from "./utils/utils";

type ReportContextType = {
  // core
  queryRequest: QueryRequest;
  setQueryRequest: (queryRequest: QueryRequest) => void;
  getDataField: (field: Field) => DbtDataField | undefined;
  getTable: (dbname: string, table: string) => DbtTable | undefined;
  mergeFields: (fields: Field[], table: string, incomingFields: Field[]) => Field[];
  validateFilter: <TErrorFlag extends boolean = false>(
    filter: Filter,
    options?: { errorMessages?: TErrorFlag }
  ) => TErrorFlag extends true ? string[] : boolean;
  getFieldDisplay: <T extends "string" | "node" | "description">(
    field: Field,
    returnType: T,
    options?: { searchString?: string; nodeDescriptionTooltip?: boolean }
  ) => T extends "node" ? ReactNode | undefined : string | undefined;
  getFieldOutput: (field: Field) => FieldOutput | undefined;

  // resources
  resourceLoading: boolean;
  dbtTables: DbtTable[] | undefined;
  joinMaps: JoinMap[] | undefined;
  filterOperatorOptions: FilterOperatorOption[] | undefined;
  filterRelativeDateValueUnitOptions: FilterRelativeDateValueUnitOption[] | undefined;
  aggregateFunctionOptions: AggregateFunctionOption[] | undefined;
  customFunctionOptions: CustomFunctionOption[] | undefined;
  intervalOptions: IntervalOption[] | undefined;
  lookups: LookupDto[] | undefined;
  joinStrategies: TableJoinStrategy[] | undefined;

  // report
  queryResult: QueryResult | undefined;
  queryResultLoading: boolean;
  queryTotalResult: QueryTotalResult | undefined;
  queryTotalResultLoading: boolean;
  fetchQueryResult: (queryRequest: QueryRequest) => Promise<void>;
  fetchQueryTotalResult: (queryRequest: QueryRequest) => Promise<void>;
  fetchExcel: (queryRequest: QueryRequest) => Promise<void | Blob>;
  fetchCsv: (queryRequest: QueryRequest) => Promise<void | Blob>;
  cancelFetchQueryResult: () => void;
  cancelFetchQueryTotalResult: () => void;
};

export const ReportContext = createContext<ReportContextType>({} as ReportContextType);

export const ReportContextProvider = (props: { children: ReactNode }) => {
  const userEmail = useSelector((state: RootState) => state.app.userEmail);

  const [queryRequest, setQueryRequest] = useState<QueryRequest>(defaultQueryRequest);
  const [resourceLoading, setResourceLoading] = useState<boolean>(false);
  const [dbtTables, setDbtTables] = useState<DbtTable[]>();
  const [filterOperatorOptions, setFilterOperatorOptions] = useState<FilterOperatorOption[]>();
  const [filterRelativeDateValueUnitOptions, setFilterRelativeDateValueUnitOptions] =
    useState<FilterRelativeDateValueUnitOption[]>();
  const [aggregateFunctionOptions, setAggregateFunctionOptions] = useState<AggregateFunctionOption[]>();
  const [customFunctionOptions, setCustomFunctionOptions] = useState<CustomFunctionOption[]>();
  const [intervalOptions, setIntervalOptions] = useState<IntervalOption[]>();
  const [lookups, setLookups] = useState<LookupDto[]>();
  const [joinMaps, setJoinMaps] = useState<JoinMap[]>();
  const [joinStrategies, setJoinStrategies] = useState<TableJoinStrategy[]>();

  const [queryResult, setQueryResult] = useState<QueryResult>();
  const [queryResultLoading, setQueryResultLoading] = useState<boolean>(false);
  const fetchQueryResultCancelToken = "fetchQueryResultCancelToken";

  const [queryTotalResult, setQueryTotalResult] = useState<QueryTotalResult>();
  const [queryTotalResultLoading, setQueryTotalResultLoading] = useState<boolean>(false);
  const fetchQueryTotalResultCancelToken = "fetchQueryTotalResultCancelToken";

  // for fast O(1) lookup of tables // TODO: put into state
  const tablesMap = new Map<string, DbtTable>(
    dbtTables?.map((table) => [`${table.dbName}.${table.name.toLowerCase()}`, table])
  );

  // for fast O(1) lookup of dataFieldss // TODO: put into state
  const dataFieldsMap = new Map<string, DbtDataField>(
    dbtTables?.flatMap((table) =>
      table.dataFields?.map((x) => [`${x.dbName}.${x.tableName}.${x.fieldName}`.toLowerCase(), x])
    )
  );

  // set default query request for users
  useEffect(() => {
    if (dbtTables) {
      let _defaultQueryRequest = defaultQueryRequest;

      if (process.env.REACT_APP_MODE === "local_web" && userEmail === "ahong@ensomata.com")
        _defaultQueryRequest = { ..._defaultQueryRequest, ...andyDefaultQueryRequest };

      _setQueryRequest(_defaultQueryRequest);
    }
  }, [dbtTables]);

  // fetch reference resources for report
  useEffect(() => {
    setResourceLoading(true);

    apiService
      .getApi()
      .report.reportControllerGetResource()
      .then((response) => {
        const resource = response.data;
        setDbtTables(resource.tables);
        setFilterOperatorOptions(resource.filterOperatorOptions);
        setFilterRelativeDateValueUnitOptions(resource.filterRelativeDateValueUnitOptions);
        setAggregateFunctionOptions(resource.aggregateFunctionOptions);
        setCustomFunctionOptions(resource.customFunctionOptions);
        setIntervalOptions(resource.intervalOptions);
        setJoinMaps(resource.joinMaps);
        setLookups(resource.lookups);
        setJoinStrategies(resource.joinStrategies);

        setResourceLoading(false);
      });
  }, []);

  const getTable = (dbName: string, table: string): DbtTable | undefined => {
    return tablesMap.get(`${dbName.toLowerCase()}.${table.toLowerCase()}`);
  };

  const getDataField = (field: Field): DbtDataField | undefined => {
    return dataFieldsMap.get(`${field.dbName}.${field.tableName}.${field.fieldName}`.toLowerCase());
  };

  const getFieldOutput = (field: Field): FieldOutput | undefined => {
    const dataField = getDataField(field);
    if (!dataField) return undefined;
    let dataType = dataField.dataType;
    let numberPrecision = dataField.numberPrecision;
    let context = dataField.context;

    if (field.transform) {
      if (field.transform === "AggregateFunction") {
        if (field.aggregateFunction === "Count" || field.aggregateFunction === "CountDistinct") {
          dataType = "Number";
          numberPrecision = undefined;
          context = undefined;
        }
      }

      if (field.transform === "CustomFunction") {
        if (
          field.customFunction === "Year" ||
          field.customFunction === "Month" ||
          field.customFunction === "Day" ||
          field.customFunction === "Week" ||
          field.customFunction === "DayOfWeek"
        ) {
          dataType = "Number";
          numberPrecision = undefined;
          context = undefined;
        }
        if (field.customFunction === "Year_Month") {
          dataType = "String";
          numberPrecision = undefined;
          context = undefined;
        }
      }
    }

    if (context === "Currency") numberPrecision = 2;

    return { dataType: dataType, numberPrecision: numberPrecision, context: context };
  };

  // important: everytime queryRequest is being set, it goes through this function where it sanitizes the request first
  // such as removing fields and filters that are not in the selected tables
  // it also calculate and set fields needed by the backend
  const _setQueryRequest = (queryRequest: QueryRequest) => {
    let resultQueryRequest = queryRequest;

    resultQueryRequest = _sanitizeQueryRequest(resultQueryRequest);

    setQueryRequest(resultQueryRequest);
  };

  // with each setQueryRequest, we want to check and set things that might have side effects:
  // sanitize: if removing a table, its corresponding fields and filters should also be removed
  const _sanitizeQueryRequest = (queryRequest: QueryRequest): QueryRequest => {
    let sanitizedQueryRequest = deepCopy(queryRequest);

    const tables = getArrayFromTableTree(sanitizedQueryRequest.tableTree).map((x) =>
      `${x.dbName}.${x.name}`.toLowerCase()
    );

    // remove fields not in selected tables
    let fields = [...sanitizedQueryRequest.fields];
    sanitizedQueryRequest.fields = fields.filter((field) => {
      return tables.includes(`${field.dbName}.${field?.tableName}`.toLowerCase());
    });

    // remove filters not in selected tables
    if (sanitizedQueryRequest.filters) {
      let filters = [...sanitizedQueryRequest.filters];
      sanitizedQueryRequest.filters = filters.filter((filter) => {
        return !filter.field.tableName || tables.includes(`${filter.field.dbName}.${filter.field.tableName}`);
      });
    }

    // remove sort fields not in in selected fields
    if (sanitizedQueryRequest.sortFields) {
      let sortFields = [...sanitizedQueryRequest.sortFields];
      sanitizedQueryRequest.sortFields = sortFields.filter((sortField) =>
        fields.map((x) => getFieldFullIdentifier(x)).includes(getFieldFullIdentifier(sortField.field))
      );
    }

    return sanitizedQueryRequest;
  };

  function getFieldDisplay<T extends "string" | "node" | "description">(
    field: Field,
    returnType: T,
    options?: { searchString?: string; nodeDescriptionTooltip?: boolean }
  ): T extends "node" ? ReactNode | undefined : string | undefined {
    const dataField = getDataField(field);

    if (!dataField) return undefined;

    let displayString = "";
    let displayNode: ReactNode;
    let description: string | undefined = undefined;
    let color = "inherit";

    if (!field.transform) {
      displayString = dataField?.displayName;

      if (options?.searchString)
        displayNode = <Highlighter searchWords={[options?.searchString]} textToHighlight={displayString} />;
      else displayNode = <>{displayString}</>;

      description = `${dataField.tableDisplayName}'s ${dataField.displayName}`;
    } else {
      switch (field.transform) {
        case "CustomFunction":
          {
            const customFunctionName = customFunctionOptions?.find((x) => x.function === field.customFunction)?.name;

            color = theme.palette.primary.main;
            displayNode = displayString = `${customFunctionName}: ${dataField?.displayName}`;
            description = `${dataField.tableDisplayName}'s ${dataField.displayName}`;

            switch (field.customFunction) {
              case "Year":
                description = `${description} (Year only)`;
                break;
              case "Month":
                description = `${description} (Month only)`;
                break;
              case "Day":
                description = `${description} (Day only)`;
                break;
              case "Week":
                description = `${description} (Week only)`;
                break;
              case "DayOfWeek":
                description = `${description} (Day of Week only)`;
                break;
              case "Year_Month":
                description = `${description} (Year and Month only)`;
                break;
            }
          }
          break;
        case "AggregateFunction":
          color = theme.palette.warning.dark;
          if (!field.aggregatePivot) {
            const aggregateFunctionOption = aggregateFunctionOptions?.find(
              (x) => x.function === field.aggregateFunction
            );
            displayNode = displayString = `${aggregateFunctionOption?.displayName}: ${dataField?.displayName}`;
            description = `${dataField.tableDisplayName}'s ${aggregateFunctionOption?.displayName} of ${dataField.displayName}`;
          } else {
            let pivotValueLabel = "";
            const pivot = field.aggregatePivot;
            const pivotField = field.aggregatePivot.field;
            const pivotDataField = getDataField(pivotField);
            const pivotValues = pivot.values;
            if (pivotValues.length > 1) {
              pivotValueLabel = "Total";
            } else {
              if (!pivotField.transform) pivotValueLabel = pivotValues[0];
              else if (pivotField.customFunction === "Month") {
                const pivotValue = pivotValues[0];
                const date = new Date(0, Number(pivotValue) - 1);
                pivotValueLabel = date.toLocaleDateString("en", { month: "short" });
              }
            }

            displayString = `${field.aggregateFunction}: ${dataField?.displayName} for ${pivotValueLabel}`;
            displayNode = (
              <Stack>
                <Typography variant="caption" lineHeight={"100%"}>
                  {field.aggregateFunction}: {dataField?.displayName} for
                </Typography>
                <Typography variant="inherit">{pivotValueLabel}</Typography>
              </Stack>
            );

            description =
              `${dataField.tableDisplayName}'s ${field.aggregateFunction} of ${dataField.displayName}\n` +
              `pivot column: ${pivotDataField?.tableDisplayName}'s ${pivotDataField?.displayName}\n` +
              `pivot value: ${pivotValueLabel}`;
          }
          break;
      }
    }

    if (returnType === "string") return displayString;
    if (returnType === "description") return description;
    if (returnType === "node") {
      const fieldOutput = getFieldOutput(field);
      return (
        <Stack direction="row" alignItems="center" spacing="xs" color={color}>
          {fieldOutput && <DataTypeIcon type={fieldOutput.dataType} />}

          <Tooltip
            title={
              options?.nodeDescriptionTooltip ? (
                <Box>
                  <pre style={{ whiteSpace: "pre-wrap" }}>{description}</pre>
                </Box>
              ) : (
                ""
              )
            }
          >
            <Stack sx={{ whiteSpace: "nowrap" }}>{displayNode}</Stack>
          </Tooltip>
        </Stack>
      ) as any;
    }

    return undefined;
  }

  const mergeFields = (fields: Field[], tableName: string, incomingFields: Field[]): Field[] => {
    let resultFields = [...fields];

    // keep all entries that are not from this table or are from this table but are in the selectedFields
    resultFields = resultFields.filter(
      (x) =>
        x.tableName !== tableName ||
        incomingFields.map((y) => getFieldFullIdentifier(y)).includes(getFieldFullIdentifier(x))
    );

    // then add all selectedFields that are not already in the fields
    resultFields = resultFields!.concat(
      incomingFields.filter((x) => !fields!.map((y) => getFieldFullIdentifier(y)).includes(getFieldFullIdentifier(x)))
    );

    return resultFields;
  };

  const validateFilter = <TErrorFlag extends boolean>(
    filter: Filter,
    options?: { errorMessages?: TErrorFlag }
  ): TErrorFlag extends true ? string[] : boolean => {
    let errors: string[] = [];
    let valid = true;

    if (!filter.field.dbName || !filter.field.tableName) {
      errors.push("Table is not selected");
      valid = false;
    }

    if (!filter.field.fieldName) {
      errors.push("Field is not selected");
      valid = false;
    }

    if (valid === false)
      if (options?.errorMessages) {
        return errors as TErrorFlag extends true ? string[] : boolean;
      } else {
        return valid as TErrorFlag extends true ? string[] : boolean;
      }

    const dataField = getDataField(filter.field);
    if (dataField === undefined) {
      errors.push("Selected Field not found");
      valid = false;
    }

    if (!filter.operator) {
      errors.push("Operator is not selected");
      valid = false;
    }

    const operatorOption = filterOperatorOptions?.find((option) => option.operator === filter.operator);
    if (!operatorOption) {
      errors.push("Operator not found");
      valid = true;
    }

    if (!dataField?.dataType) {
      errors.push("Selected Field data type not found");
      valid = false;
    }

    if (dataField && !operatorOption?.applicableFieldTypes.includes(dataField.dataType)) {
      errors.push("Operator not valid");
      valid = false;
    }

    // check values depending on data type and operator
    const operator = operatorOption?.operator;

    if (
      operator === "is" ||
      operator === "is_not" ||
      operator === "is_greater_than" ||
      operator === "is_greater_than_or_equals" ||
      operator === "is_less_than" ||
      operator === "is_less_than_or_equals" ||
      operator === "contains" ||
      operator === "does_not_contain" ||
      operator === "starts_with" ||
      operator === "ends_with" ||
      operator === "is_after" ||
      operator === "is_on_or_after" ||
      operator === "is_before" ||
      operator === "is_on_or_before"
    ) {
      if (filter.values.length === 0 || !filter.values[0]) {
        errors.push("Value is not entered");
        valid = false;
      }
    }

    if (operator === "is_between") {
      if (!filter.values?.[0]) {
        errors.push("Value (From) is not entered");
        valid = false;
      }

      if (!filter.values?.[1]) {
        errors.push("Value (To) is not entered");
        valid = false;
      }
    }

    if (
      operator === "is_after_relative" ||
      operator === "is_before_relative" ||
      operator === "is_on_or_after_relative" ||
      operator === "is_on_or_before_relative"
    ) {
      if (!filter.values?.[0]) {
        errors.push("Number is not entered");
        valid = false;
      }

      if (!filter.values?.[1]) {
        errors.push("Unit is not selected");
        valid = false;
      }
    }

    if (options?.errorMessages) {
      return errors as TErrorFlag extends true ? string[] : boolean;
    } else {
      return valid as TErrorFlag extends true ? string[] : boolean;
    }
  };

  const fetchQueryResult = (queryRequest: QueryRequest) => {
    setQueryResult(undefined);

    let postingQueryRequest = deepCopy(queryRequest);
    postingQueryRequest.filters = postingQueryRequest.filters?.filter((filter) => validateFilter(filter));

    setQueryResultLoading(true);
    return apiService
      .getApi()
      .report.reportControllerQuery(postingQueryRequest, { cancelToken: fetchQueryResultCancelToken })
      .then((response) => {
        setQueryResultLoading(false);
        setQueryResult(response.data);
      })
      .catch(() => {
        setQueryResultLoading(false);
        setQueryResult(undefined);
      });
  };

  const fetchQueryTotalResult = (queryRequest: QueryRequest) => {
    setQueryTotalResult(undefined);

    let postingQueryRequest = deepCopy(queryRequest);
    postingQueryRequest.filters = postingQueryRequest.filters?.filter((filter) => validateFilter(filter));

    setQueryTotalResultLoading(true);
    return apiService
      .getApi()
      .report.reportControllerQueryTotal(postingQueryRequest, { cancelToken: fetchQueryTotalResultCancelToken })
      .then((response) => {
        setQueryTotalResultLoading(false);
        setQueryTotalResult(response.data);
      })
      .catch(() => {
        setQueryTotalResult(undefined);
        setQueryTotalResultLoading(false);
      });
  };

  const fetchExcel = (queryRequest: QueryRequest) => {
    let postingQueryRequest = deepCopy(queryRequest);

    postingQueryRequest.filters = postingQueryRequest.filters?.filter((filter) => validateFilter(filter));

    setQueryResultLoading(true);
    return apiService
      .getApi()
      .report.reportControllerDownloadExcel(postingQueryRequest, { cancelToken: fetchQueryResultCancelToken })
      .then((response) => {
        setQueryResultLoading(false);
        return response.blob();
      })
      .catch((error) => {
        setQueryResultLoading(false);
        console.log("SPREADSHEET ERROR: " + error);
        throw error;
      });
  };

  const fetchCsv = (queryRequest: QueryRequest) => {
    let postingQueryRequest = deepCopy(queryRequest);

    postingQueryRequest.filters = postingQueryRequest.filters?.filter((filter) => validateFilter(filter));

    setQueryResultLoading(true);
    return apiService
      .getApi()
      .report.reportControllerDownloadCsv(postingQueryRequest, { cancelToken: fetchQueryResultCancelToken })
      .then((response) => {
        setQueryResultLoading(false);
        return response.blob();
      })
      .catch((error) => {
        setQueryResultLoading(false);
        console.log("SPREADSHEET ERROR: " + error);
      });
  };

  const cancelFetchQueryResult = () => {
    apiService.getApi().abortRequest(fetchQueryResultCancelToken);
  };

  const cancelFetchQueryTotalResult = () => {
    apiService.getApi().abortRequest(fetchQueryTotalResultCancelToken);
  };

  return (
    <ReportContext.Provider
      value={{
        queryRequest: queryRequest,
        setQueryRequest: (queryRequest) => _setQueryRequest(queryRequest),
        getDataField: getDataField,
        getTable: getTable,
        mergeFields: mergeFields,
        validateFilter: validateFilter,
        getFieldDisplay: getFieldDisplay,
        getFieldOutput: getFieldOutput,

        resourceLoading: resourceLoading,
        dbtTables: dbtTables,
        joinMaps: joinMaps,
        filterOperatorOptions: filterOperatorOptions,
        filterRelativeDateValueUnitOptions: filterRelativeDateValueUnitOptions,
        aggregateFunctionOptions: aggregateFunctionOptions,
        customFunctionOptions: customFunctionOptions,
        intervalOptions: intervalOptions,
        lookups: lookups,
        joinStrategies: joinStrategies,

        fetchQueryResult: fetchQueryResult,
        fetchQueryTotalResult: fetchQueryTotalResult,
        fetchExcel: fetchExcel,
        fetchCsv: fetchCsv,
        cancelFetchQueryResult: cancelFetchQueryResult,
        cancelFetchQueryTotalResult: cancelFetchQueryTotalResult,
        queryResult: queryResult,
        queryResultLoading: queryResultLoading,
        queryTotalResult: queryTotalResult,
        queryTotalResultLoading: queryTotalResultLoading,
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
};
