import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { Employee, ReportSegment, TestReportQuery, TestReportResult } from "api/Api";
import { ReaderPicker } from "../../ReaderPicker/ReaderPicker";
import { useState } from "react";
import { ReportResults } from "../ReportResults";
import apiService from "services/apiService";
import { DatePicker } from "components";
import { getQuerySafeDate, getUIDateFormat } from "utils";


export function ReportSegmentPreview({reportSegment}: {reportSegment:ReportSegment})
{
    const [reportContext, setReportContext] = useState({
        reportDate: getQuerySafeDate(),
    } as TestReportQuery);
    const [loading, setLoading] = useState(false);
    const [reportResults, setReportResults] = useState<TestReportResult|undefined>(undefined);
    function runReportQuery() {
        apiService
        .getApi()
        .admin.reportSegmentControllerTestReportQuery({
            ...reportContext,
            query: reportSegment.sqlQuery,
        })
        .then((response) => {
            console.log(response);
            setReportResults(response.data as TestReportResult);
            setLoading(false);
        }).catch((e) => {
            debugger;
        });
    }
    return <Stack spacing={2}>
        <Box>
            <Typography variant="body1">View the report as:</Typography>
        </Box>
        <Stack direction="row" spacing={2}>
        <Box width="50%">
             <ReaderPicker
                reader={undefined}
                setReader={(e: Employee) => {
                    setReportContext({
                        ...reportContext,
                        facilityId: `${e?.facility_id}`,
                    })
                }}
            />
            </Box>
            <Box width="50%">
                <DatePicker 
                    label="Report Date"
                    value={reportContext?.reportDate ?? new Date()}
                    onChange={(e)=>{
                    setReportContext({
                        ...reportContext,
                        reportDate: getQuerySafeDate(e),
                    });
                }} />
            </Box>
        </Stack>
        <Stack direction="row">
            <Box width="33%" textAlign="center">user_id: {reportContext?.userId ?? 'none'}</Box>
            <Box width="33%" textAlign="center">facility_id: {reportContext?.facilityId ?? 'none'}</Box>
            <Box width="33%" textAlign="center">report_date: {reportContext?.reportDate ?? getUIDateFormat(new Date())}</Box>
        </Stack>
        <Box textAlign="right">
                <Button 
                    variant="contained"
                    onClick={()=>{
                    runReportQuery();
                    setLoading(true);
                }}>Run Report Query</Button>
            </Box>
        <Divider />
        <Paper>
            <Box padding={2}>
                <div>
                    <h2>{reportSegment.name}</h2>
                    <p>{reportSegment.description}</p>
                    {reportResults && reportResults?.results?.length > 0 && <ReportResults reportResults={reportResults}  />}
                </div>
            </Box>
        </Paper>
    </Stack>;
}