import { TestReportQuery, TestReportResult } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useSegmentQuery(reportContext: TestReportQuery): [TestReportResult | undefined, boolean] {
  const [reportResults, setReportResults] = useState<TestReportResult | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loading || reportResults || !reportContext.query || reportContext.query === "") {
      return;
    }
    setLoading(true);
    apiService
      .getApi()
      .admin.reportSegmentControllerTestReportQuery(reportContext)
      .then((response) => {
        console.log(response);
        setReportResults(response.data as TestReportResult);
        setLoading(false);
      })
      .catch((e) => {
        debugger;
      });
  }, [reportContext]);
  return [reportResults, loading];
}
