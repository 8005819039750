import { Employee, ReportSegment as iReportSegment, TestReportQuery } from "api/Api";
import { useSegmentQuery } from "hooks/email/segments/useSegmentQuery";
import { ReportResults } from "../../ReportSegments/ReportResults";


export function ReportSegment({segment, reader, index, reportDate}: {segment: iReportSegment, reader: Employee | undefined, index: number, reportDate: string}){

    // find the segment in the potential report segments
    const [queryResults, loadingQueryResults] = useSegmentQuery({
        query: segment.sqlQuery,
        facilityId: `${reader?.facility_id ?? 0}`,
        reportDate
    });
    
    return <div key={`segment${index}`} style={{
        padding: "12px",
        border: "1px solid #ccc",
        marginBottom: "12px"
    
    }}>
        <h4>{segment.name}</h4>
        {segment.description && <p>{segment.description}</p>}
        
        {loadingQueryResults && <div>Loading...</div>}

        {!loadingQueryResults && queryResults && <ReportResults reportResults={queryResults} />}
    </div>
}