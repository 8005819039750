export type Spacing = "xs" | "sm" | "md" | "lg";

export const SPACING_XS: number = 0.5;
export const SPACING_SM: number = 1.5;
export const SPACING_MD: number = 3;
export const SPACING_LG: number = 5;

export const getSpacing = (spacing: Spacing | undefined): number | undefined => {
  switch (spacing) {
    case "xs":
      return SPACING_XS;
    case "sm":
      return SPACING_SM;
    case "md":
      return SPACING_MD;
    case "lg":
      return SPACING_LG;
    default:
      return undefined;
  }
};
