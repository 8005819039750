import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MenuItem, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, ButtonGroup, Divider, FilterButton, Menu, PageHeader, Stack } from "components";
import { useContext, useEffect, useState } from "react";
import { ReportContext } from "../ReportContextProvider";
import { getArrayFromTableTree } from "../utils/table-tree-utils";
import { InspectQueryRequest } from "./InspectQueryRequest";
import { InspectSql } from "./InspectSql";
import { formatMs } from "services";
import { EnterQueryRequest } from "./EnterQueryRequest";

export const ReportPageHeader = (props: {
  setIsFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNavOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  const reportContext = useContext(ReportContext);
  const [submitAnchorEl, setSubmitAnchorEl] = useState<null | HTMLElement>(null);
  const loading = reportContext.queryResultLoading || reportContext.queryTotalResultLoading;
  const [seconds, setSeconds] = useState<number>();
  const [timerActive, setTimerActive] = useState(false);
  const [excelDownloadTime, setExcelDownloadTime] = useState<number>();
  const [csvDownloadTime, setCsvDownloadTime] = useState<number>();

  const [isInspectSql, setIsInspectSql] = useState<boolean>(false);
  const [isInspectQueryRequest, setIsInspectingQueryRequest] = useState<boolean>(false);
  const [isEnterQueryRequest, setIsEnterQueryRequest] = useState<boolean>(false);

  useEffect(() => {
    let intervalId;

    if (timerActive) {
      setSeconds(0);

      // Start the timer only if isActive is true
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds! + 1); // Increment the seconds state every second
      }, 1000);
    } else {
      setSeconds(undefined);
    }

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, [timerActive]);

  const startTimer = () => {
    setTimerActive(true);
    setSeconds(0);
  };

  const endTimer = () => {
    setTimerActive(false);
    setSeconds(0);
  };

  const runReport = () => {
    props.setNavOpen(false);
    props.setIsFiltersOpen(false);

    startTimer();

    Promise.all([
      reportContext.fetchQueryResult(reportContext.queryRequest),
      reportContext.fetchQueryTotalResult(reportContext.queryRequest),
    ]).then(() => {
      endTimer();
    });
  };

  const cancel = () => {
    endTimer();
    reportContext.cancelFetchQueryResult();
    reportContext.cancelFetchQueryTotalResult();
  };

  const downloadCsv = () => {
    const startTime = Date.now();
    startTimer();

    reportContext.fetchCsv(reportContext.queryRequest!).then((response) => {
      setCsvDownloadTime(Date.now() - startTime);
      endTimer();

      const fileName = "report.csv";
      // TODO
      // const fileName = `${queryRequest.tables.map((table) => table.name).join("-")}_${
      //   new Date().toISOString().split("T")[0]
      // }.csv`;

      // create file link in browser's memory
      const href = URL.createObjectURL(response!);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const downloadExcel = () => {
    const startTime = Date.now();
    startTimer();

    reportContext.fetchExcel(reportContext.queryRequest!).then((response) => {
      setExcelDownloadTime(Date.now() - startTime);
      endTimer();

      const fileName = "report.xlsx";
      // TODO
      // const fileName = `${queryRequest.tables.map((table) => table.name).join("-")}_${
      //   new Date().toISOString().split("T")[0]
      // }.csv`;

      // create file link in browser's memory
      const href = URL.createObjectURL(response!);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const canRunReport = reportContext.queryRequest?.tableTree;

  const selectedTables = getArrayFromTableTree(reportContext.queryRequest?.tableTree);

  const title =
    selectedTables.map((table) => reportContext.getTable(table.dbName, table.name)?.displayName).join(", ") ||
    "BI Report";

  const submitButton = () => {
    return (
      <ButtonGroup variant="contained">
        {!loading ? (
          <Button onClick={runReport} disabled={!canRunReport}>
            Run Report
          </Button>
        ) : (
          <Button onClick={cancel} variant="outlined">
            <Stack direction="row" spacing="sm" alignItems="center">
              <CircularProgress size={15} />

              <Stack direction="row" spacing="sm" alignItems="center">
                <Typography variant="inherit">Cancel</Typography>
                <Typography variant="caption" lineHeight="100%" textTransform={"none"}>
                  {seconds}s
                </Typography>
              </Stack>
            </Stack>
          </Button>
        )}
        <Button size="small" onClick={(e) => setSubmitAnchorEl(e.currentTarget)}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
    );
  };

  const menu = () => {
    return (
      <Menu anchorEl={submitAnchorEl} open={Boolean(submitAnchorEl)} onClose={() => setSubmitAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            downloadCsv();
            setSubmitAnchorEl(null);
          }}
          disabled={!canRunReport || loading}
        >
          <Stack>
            <Typography>Download CSV</Typography>
            {csvDownloadTime !== undefined && (
              <Typography variant="caption" lineHeight={"100%"}>
                {formatMs(csvDownloadTime)}
              </Typography>
            )}
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            downloadExcel();
            setSubmitAnchorEl(null);
          }}
          disabled={!canRunReport || loading}
        >
          <Stack>
            <Typography>Download Excel</Typography>
            {excelDownloadTime !== undefined && (
              <Typography variant="caption" lineHeight={"100%"}>
                {formatMs(excelDownloadTime)}
              </Typography>
            )}
          </Stack>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => {
            setIsInspectSql(true);
            setSubmitAnchorEl(null);
          }}
          disabled={!canRunReport}
        >
          Inspect SQL
        </MenuItem>

        <MenuItem
          onClick={() => {
            setIsInspectingQueryRequest(true);
            setSubmitAnchorEl(null);
          }}
        >
          [dev]: Inspect Params
        </MenuItem>

        <MenuItem
          onClick={() => {
            setIsEnterQueryRequest(true);
            setSubmitAnchorEl(null);
          }}
        >
          [dev]: Enter Params
        </MenuItem>
      </Menu>
    );
  };

  return (
    <PageHeader
      title={title}
      rightHeader={
        <>
          <FilterButton
            onClick={() => props.setIsFiltersOpen((filterOpen) => !filterOpen)}
            filtersCount={
              reportContext.queryRequest?.filters?.filter((x) => x.active && reportContext.validateFilter(x))?.length
            }
          />

          {submitButton()}

          {menu()}

          {isInspectSql && (
            <InspectSql queryRequest={reportContext.queryRequest} close={() => setIsInspectSql(false)} />
          )}

          {isInspectQueryRequest && (
            <InspectQueryRequest
              queryRequest={reportContext.queryRequest}
              close={() => setIsInspectingQueryRequest(false)}
            />
          )}

          {isEnterQueryRequest && (
            <EnterQueryRequest
              queryRequest={reportContext.queryRequest}
              setQueryRequest={(value) => reportContext.setQueryRequest(value)}
              close={() => setIsEnterQueryRequest(false)}
            />
          )}
        </>
      }
    />
  );
};
