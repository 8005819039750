import { Box, Stack, Typography } from "@mui/material";

import { ReportSegment } from "api/Api";
import { useEffect, useState } from "react";
import { Input } from "components";
import { ReportSegmentPreview } from "./editor-helpers/ReportSegmentPreview";


export function SqlQueryEditor({reportSegment, onSegmentChange}:{reportSegment:ReportSegment, onSegmentChange:Function}) {
    const [isChangefulQuery, setIsChangefulQuery] = useState(false);

    useEffect(() => {
        setIsChangefulQuery(checkIsChangefulQuery(reportSegment.sqlQuery));
    }, [reportSegment.sqlQuery]);


    function checkIsChangefulQuery(query: string = ''): boolean {
        // Trim whitespace from the query and convert to uppercase for case-insensitive comparison
        const trimmedQuery = query?.trim()?.toUpperCase() ?? '';
        
        return /INSERT\s|DELETE\s|UPDATE\s/.test(trimmedQuery);
    }

    return <Stack spacing={2}>
        <Box>
            <h3>SQL Query</h3>
        </Box>
        <Box>
            <Input 
                style={{fontFamily: 'monospace'}}
                label="Report Sql Query"
                value={reportSegment.sqlQuery}
                onChange={(e) => {
                        onSegmentChange({
                            ...reportSegment,
                            sqlQuery : e.target.value
                        });
                }}
                multiline
                fullWidth
                rows={10}
                />
                {
                    isChangefulQuery && 
                    <Box>
                        <p style={{color: 'red'}}>This query contains a modification statement (INSERT, DELETE, UPDATE) and will not be executed</p>
                    </Box>
                }
        </Box>
        <Box>
            <Typography variant="h6">Variables</Typography>
            <p>
                You can use the following variables in your query:
            </p>
            <ul>
                <li>:facility-id: - The facility ID of the user/reader</li>
                <li>:user-id: - the user ID of the user/reader</li>
                <li>:report-date: - the report date as provided in the URL or the current date</li>
            </ul>
            <Typography variant="h6">Example</Typography>
            <Typography variant="body1" style={{fontFamily: "monospace"}}>
                SELECT * FROM table WHERE facility_id = :facility-id: AND user_id = :user-id: AND date {">"}= :start-date:
            </Typography>
        </Box>
    </Stack>
}