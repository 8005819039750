import { Box, Button, Container, Paper, Select, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReaderPicker } from "../../ReaderPicker/ReaderPicker";
import { ReportSegment } from "./ReportSegment";
import { getQuerySafeDate } from "utils";
import { DatePicker } from "components";
import { EmailableReport, Employee } from "api/Api";
import { Link } from "react-router-dom";

/** */

export function ReportPreview({report}: {report: EmailableReport}){
    const [reader, setReader] = useState<Employee|undefined>(undefined);
    const [reportDate, setReportDate] = useState<string>(getQuerySafeDate(new Date()));
    const [reportContextValid, setReportContextValid] = useState(false);
    useEffect(() =>{
        if(reader && reportDate){
            setReportContextValid(true);
        } else {
            setReportContextValid(false);
        }
    }, [reader, reportDate]);
    
    return <Container>
        <Stack direction="row" spacing={2}>
        <Box width="50%">
             <ReaderPicker
                reader={undefined}
                setReader={setReader}
            />
            </Box>
            <Box width="50%">
                <DatePicker 
                    label="Report Date"
                    value={reportDate ?? new Date()}
                    onChange={(e)=>{
                    setReportDate(getQuerySafeDate(e));
                }} />
            </Box>
        </Stack>
        <Stack direction="row" spacing={2} padding={2}>
            <Box width="33%" textAlign="center">badge number: {reader?.badge_number ?? 'none'}</Box>
            <Box width="33%" textAlign="center">facility_id: {reader?.facility_id ?? 'none'}</Box>
            <Box width="33%" textAlign="center">report_date: {reportDate}</Box>
        </Stack>
        <Paper style={{padding: "2rem"}}>
        {!reportContextValid && <Typography variant="h6">Please select a reader and a report date to preview the report.</Typography>}
        {(reportContextValid) && <>
            <div>
                <div>
                    <ReportHeader report={report} />
                </div>
                <div style={{padding: '12px'}}>
                    {report?.preamble ?? ""}
                </div>
                {(!report.segments || report.segments?.length === 0) &&  <div style={{padding: "12px"}}>No segments selected. Please select some segments to preview the report.</div>}
                {report.segments?.map((segment, idx) => {
                    return <ReportSegment reader={reader} key={`repseg${idx}`} segment={segment} index={idx} reportDate={reportDate} />
                })}
            </div>
            <ReportFooter />
            {report.id > 0 &&<Box>
                <Link to={`/custom-reports/report/${report.id}/${reader?.badge_number}/${reportDate}`} target="_blank">
                    <Button>View Report</Button>
                </Link>
            </Box>}
            </>
        }
    </Paper>
    </Container>;
}

export function ReportHeader({report}: {report:EmailableReport}) {
    return (
    <div style={{backgroundColor: "rgb(17,25,42)", minHeight: "74px" }}>
        <div style={{display: "inline-block", padding: "12px"}}>
            <img width="50px" height="50px" src="/ensomata-word-logo-white.svg" alt="logo" />
        </div>
        <div style={{
            display:"inline-block", 
            verticalAlign: "baseline", 
            lineHeight: "32px", 
            height: "50px",
            color: "#fff", 
            overflow: "hidden", 
            textOverflow:"ellipses", 
            padding: "12px", 
            fontWeight: 900, 
            fontSize: "30px",
            whiteSpace: "nowrap",
            width: "calc(100% - 86px)"
            }}>
            {report.reportName}
        </div>
    </div>);
}

export function ReportFooter() {
    return (<>
        <div>
            <div style={{padding: "12px"}}>
                <p>Powered by <a href="https://ensomata.com" target="_blank" rel="noreferrer">Ensomata</a></p>
            </div>
        </div>
    </>);
}

