import { IconButton } from "components";
import CancelIcon from '@mui/icons-material/Cancel';

export type CancelIconButtonProps = {
  onClick: () => void;
};

export const CancelIconButton = ({ onClick }: CancelIconButtonProps) => {
  return (
    <IconButton onClick={onClick}>
      <CancelIcon />
    </IconButton>
  );
};
