import React from "react";
import { Box, Stack } from "@mui/material";
import { QuerySpeedReport } from "./QuerySpeedReport/QuerySpeedReport";
import { RecentUsers } from "./RecentUsers/RecentUsers";
import { SharedPresetQueries } from "./SharedQueries/SharedQueries";

export function AdminDashboard(props:any) {

    return <>
        <Stack direction="row" spacing="2" useFlexGap>
                <Stack width="50%">
                    <QuerySpeedReport />
                </Stack>
                <Stack width="50%" direction="column" spacing="2" useFlexGap>
                    <Box style={{maxHeight: '33%'}}>
                        <RecentUsers />
                    </Box>
                    <Box style={{maxHeight: '33%'}}>
                        <SharedPresetQueries />
                    </Box>
                </Stack>
        </Stack>

    </>
}