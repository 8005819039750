import { Employee } from "api/Api";
import { Select } from "components/mui/Select";
import { usePotentialReaders } from "hooks/email/usePotentialReaders";
import { usePotentialRolls } from "hooks/email/usePotentialRolls";
import { useState } from "react";


export function ReaderPicker({reader, setReader}: {reader: Employee | undefined, setReader: Function}) {
    const [selectedRoll, setSelectedRoll] = useState<string|undefined>(undefined);
    const [potentialRolls, loadingPotentialRolls] = usePotentialRolls();
    const [potentialReaders, loadingPotentialReaders] = usePotentialReaders(selectedRoll);
    return <>
        <Select 
            value={reader}
            onChange={(e, value) => {setReader(value)}}
            label="Reader"
            options={potentialReaders}
            getOptionLabel={(reader) => `${reader.last_name}, ${reader.first_name} -- ${reader.maplocation_longname} -- ${reader.job_title} (${reader.department_name})`}
        />
            
    </>
}