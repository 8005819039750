import SearchIcon from '@mui/icons-material/Search';
import { Box, Checkbox, FormControl, FormLabel, Input, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { EmployeeEmail } from "api/Api";
import { usePotentialSubscribers } from "hooks/email/usePotentialSubscribers";
import { useEffect, useState } from "react";
import { sortBy } from "lodash";

export function SubscriptionPicker({subscribers, onChange}: {subscribers: string[], onChange: Function}) {
    const [potentialSubscribers, potentialSubscribersLoading] = usePotentialSubscribers();
    const [filteredPotentialSubscribers, setFilteredPotentialSubscribers] = useState<EmployeeEmail[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    useEffect(() => {
        if(potentialSubscribersLoading || potentialSubscribers.length === 0){ return; }
        // first person shooter, or filtered potential subscribers
        const fps = potentialSubscribers.filter(employee => (
            searchValue === "" ||
            employee?.email?.toLowerCase().includes(searchValue.toLowerCase()) || 
            employee?.firstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            employee?.lastName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            employee?.departmentName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            employee?.jobTitle?.toLowerCase().includes(searchValue.toLowerCase()) ||
            employee?.email?.toLowerCase().includes(searchValue.toLowerCase())
        ));
        
        setFilteredPotentialSubscribers(sortBy(fps, (e)=> subscribers.includes(e.email) ? 0 : 1));
    }, [potentialSubscribers, potentialSubscribersLoading, searchValue]);

    if(potentialSubscribersLoading){ 
        return <div>Loading subscriber list...</div>;
    }
    
    return (
        <Stack direction="column" spacing="4" padding="4" margin="4">
            <Box>
                <label>Subscribers</label>
            </Box>
            <Box>
                <FormControl fullWidth>
                    <FormLabel>
                        <SearchIcon fontSize='small' style={{width: '2rem'}} />
                        <Input name="search" value={searchValue}
                        style={{width: "calc(100% - 2rem)"}}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }} 
                        placeholder="Search by name, email, department, or title"
                        />
                    </FormLabel>
                </FormControl>
            </Box>
            <Box style={{maxHeight: "20rem", overflow: "auto"}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox"></TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Department</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Title</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {filteredPotentialSubscribers.map((employee) => {
                            const isChecked = subscribers.includes(employee.email);
                            return (
                                <TableRow key={employee.email}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            id={employee.email}
                                            name={employee.email}
                                            checked={isChecked}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    onChange([...subscribers, employee.email]);
                                                } else {
                                                    onChange(subscribers.filter((email) => email !== employee.email));
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {employee.firstName} {employee.lastName}
                                    </TableCell>
                                    <TableCell>{employee.email}</TableCell>
                                    <TableCell>{employee.departmentName}</TableCell>
                                    <TableCell>{employee.location}</TableCell>
                                    <TableCell>{employee.jobTitle}</TableCell>
                                </TableRow>
                            );
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Stack>
    
    );
}
