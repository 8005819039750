import { Box, Button, Stack, Typography } from "@mui/material";
import { ReportSegment } from "api/Api";
import { DeleteIconButton } from "components";
import { Select } from "components/mui/Select";
import { usePotentialReportSegments } from "hooks/email/usePotentialReportSegments";
import { useEffect, useState } from "react";


export function ReportSegmentPicker({segments, onSegmentPickerChange, reportType}: {
    segments: ReportSegment[];
    onSegmentPickerChange: Function;
    reportType: "email" | "dashboard";
}) {
    const [potentialReportSegments, potentialReportSegmentsLoading] = usePotentialReportSegments(reportType);
    const [tempSegments, setTempSegments] = useState<ReportSegment[]>([]);


    useEffect(() => {
        if(segments.length !== tempSegments.length){
            console.log(`setting segments: ${segments.length}`);
            setTempSegments(segments);
        }
    }, [segments]);

    const addNewReportSegment = () => {
        setTempSegments([...tempSegments, {
            id: 0,
            name: "",
        } as ReportSegment]);
    };

    useEffect(() => {
        const temp = tempSegments.filter(segment => typeof segment !== 'undefined' && JSON.stringify(segment) !== '{}');
        onSegmentPickerChange(temp);
    }, [tempSegments]);

    // console.log(tempSegments);
    if(potentialReportSegmentsLoading){
        return <Box>Loading...</Box>;
    }

    return (<Stack>
        <Box>
            <h3>Report Segments</h3>
        </Box>
        <Box>
            {tempSegments.map((segment, index) => {
                return (
                    <Box key={index}>
                        <ReportSegmentForm
                            selectedReport={segment}
                            selectedReports={segments} 
                            potentialReportSegments={potentialReportSegments} 
                            onChange={(e) => {
                                if(e === undefined){
                                    tempSegments.splice(index, 1);
                                    setTempSegments([...tempSegments]);
                                } else {
                                    tempSegments[index] = e;
                                    setTempSegments([...tempSegments]);
                                }
                            }} 
                            />
                    </Box>
                );
            })}
        </Box>
        <Box>
            <Button onClick={()=> {
                addNewReportSegment();
            }} fullWidth>
                Add Segment
            </Button>
        </Box>
    </Stack>);
}

function ReportSegmentForm({selectedReport, selectedReports, potentialReportSegments, onChange}: {
    selectedReport?: ReportSegment,
    selectedReports: ReportSegment[],
    potentialReportSegments: ReportSegment[] | undefined,
    onChange: Function,
}){
    const [tempSelectedReport, setTempSelectedReport] = useState(selectedReport);
    useEffect(() => {
        onChange(tempSelectedReport);
    }, [tempSelectedReport])
    if(potentialReportSegments === undefined){
        return <>loading report segments</>
    }
    return <Box>
            <Stack direction="column">
                <Box>
                    <label>Select a Section</label>
                </Box>
                <Stack direction="row" width="100%">
                    <Select value={tempSelectedReport} 
                        onChange={(e, value) => {
                            if(value === null ){
                                if(window.confirm(`Delete this report panel?`)){
                                    setTempSelectedReport(undefined);
                                }
                            } else if (value !== null) {
                                setTempSelectedReport(value);
                            }
                        }}
                        options={potentialReportSegments}
                        getOptionLabel={(segment: ReportSegment) => {
                            return segment.name;
                        }} />
                    
                    <DeleteIconButton onClick={()=>{
                        setTempSelectedReport(undefined);
                    }} />
                </Stack>
                {tempSelectedReport && <Box>
                    <SelectedReportHeaders selectedReport={tempSelectedReport} />
                </Box>}
            </Stack>
        </Box>
}

export function SelectedReportHeaders({selectedReport}: {selectedReport: any}){
    return  <>{selectedReport?.description && selectedReport?.description?.length > 0 && 
                    <Box><Typography variant="caption">{selectedReport.description}</Typography></Box>}</>
}