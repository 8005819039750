import { DbtTable, Field, Filter as FilterModel, FilterRelativeDateValueUnit } from "api/Api";
import { DataType, FilterOperator } from "api/Api-extension";
import { Box, CancelIconButton, Grid, Stack } from "components";
import produce from "immer";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { getArrayFromTableTree } from "pages/ReportPage/utils/table-tree-utils";
import { useContext } from "react";
import { FilterFieldInput } from "./FilterFieldInput";
import { FilterOperatorInput } from "./FilterOperatorInput";
import { FilterStatus } from "./FilterStatus";
import { FilterTableInput } from "./FilterTableInput";
import { FilterValueInput } from "./FilterValueInput";

export const Filter = (props: {
  filter: FilterModel;
  setFilter: (filter: FilterModel) => void;
  removeFilter: () => void;
}) => {
  const reportContext = useContext(ReportContext);
  const tableTreeArray = getArrayFromTableTree(reportContext?.queryRequest?.tableTree);
  const dbtTables = tableTreeArray.map((x) => reportContext.getTable(x.dbName, x.name) || ({} as DbtTable)) ?? [];
  const selectedDbtTable = dbtTables.find(
    (x) =>
      x.dbName?.toLowerCase() === props.filter.field?.dbName?.toLowerCase() &&
      x.name?.toLowerCase() === props.filter.field?.tableName?.toLowerCase()
  );
  const fieldOutputDataType = reportContext.getFieldOutput(props.filter.field)?.dataType;

  const setSelectedTable = (value: DbtTable | undefined) => {
    props.setFilter(
      produce(props.filter, (draft) => {
        draft.field = {
          dbName: value ? value.dbName : "",
          tableName: value ? value.name : "",
          fieldName: "",
        };
        draft.values = [];
      })
    );
  };

  const setSelectedField = (value: Field) =>
    props.setFilter(
      produce(props.filter, (draft) => {
        draft.field = value;
        draft.values = [];
      })
    );

  const setSelectedOperator = (value: FilterOperator) => {
    props.setFilter(
      produce(props.filter, (draft) => {
        draft.operator = value;
        draft.values = [];
      })
    );
  };


  return (
    <Box width="100%">
      <Grid container width="100%">
        <Grid item display="inline-flex" alignItems={"center"}>
          <FilterStatus
            filter={props.filter}
            setActive={(value) => props.setFilter({ ...props.filter, active: value })}
          />
        </Grid>

        <Grid item flexGrow={1} flexBasis={0}>
          <FilterTableInput
            dbtTables={dbtTables}
            selectedDbtTable={selectedDbtTable}
            setSelectedDbtTable={setSelectedTable}
          />
        </Grid>

        <Grid item flexGrow={1} flexBasis={0}>
          <FilterFieldInput
            dbtDataFields={selectedDbtTable?.dataFields || []}
            selectedField={props.filter.field}
            setSelectedField={setSelectedField}
          />
        </Grid>

        <Grid item flexGrow={1} flexBasis={0}>
          <FilterOperatorInput
            selectedOperator={props.filter.operator}
            setSelectedOperator={setSelectedOperator}
            selectedField={props.filter.field}
          />
        </Grid>

        <Grid item flexGrow={2} flexBasis={0}>
          <FilterValueInput filter={props.filter} setFilter={(value) => props.setFilter(value)} />
        </Grid>

        {/* Filter Actions */}
        <Grid item display="inline-flex" alignItems={"center"}>
          <Stack direction="row" spacing="sm" alignItems={"center"}>
            <CancelIconButton onClick={() => props.removeFilter()} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
