import {ReportSegment as iReportSegment} from "api/Api";
import { Box, Container, Stack } from "@mui/material";
import { Button, Input } from "components";
import { useReportSegment } from "hooks/email/segments/useReportSegment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiService from "services/apiService";
import { SqlQueryEditor } from "./SqlQuertyEditor";
import { ReportSegmentPreview } from "./editor-helpers/ReportSegmentPreview";
import { useDispatch } from "react-redux";
import { setAppToast } from "redux/reducers/appSlice";
import { newToast } from "services";



export function ReportSegment() {
    const params = useParams();
    const segmentId = parseInt(params?.id as string ?? 0);
    const [segment, segmentLoading] = useReportSegment(segmentId);
    const dispatch = useDispatch();
    const [tempSegment, setTempSegment] = useState({
        id: 0,
        name: "",
        description: "",
    } as iReportSegment);

    useEffect(() => {
        if(segmentId === 0){
            return;
        }
        if(segment)
            setTempSegment(segment);
    }, [segmentId, segment, segmentLoading]);

    function saveReportSegment() {
        if(tempSegment.id === 0){
            apiService.getApi()
            .admin
            .reportSegmentControllerCreateReportSegment(tempSegment)
            .then((response) => {
                setTempSegment(response.data);
                dispatch(setAppToast(newToast("success", "Report Segment Saved")));
            });
        } else {
            apiService.getApi()
            .admin
            .reportSegmentControllerPostReportSegment(tempSegment.id, tempSegment)
            .then((response) => {
                setTempSegment(response.data);
                dispatch(setAppToast(newToast("success", "Report Segment Saved")));
            });
        }
    }

    if(segmentLoading){
        return <div>Loading...</div>
    }

    if(typeof segment === undefined){
        return <div>Segment not found</div>
    }
    
    return <Container>
             <Box>
                <h1>{tempSegment?.name}</h1>
            </Box>
            <Stack direction="row" spacing={2}>
                <Box style={{width: "40%"}}>
                    <Stack spacing={2}>            
                        <Box>
                            <Input
                                label="Name"
                                value={tempSegment?.name}
                                onChange={(e) => {
                                    setTempSegment({...tempSegment, name: e.target.value});
                                }}
                                />
                        </Box>
                        <Box>
                            <Input 
                                label="Description"
                                multiline={true}
                                value={tempSegment?.description}
                                onChange={(e) => {
                                    setTempSegment({...tempSegment, description: e.target.value});
                                }}
                                />
                        </Box>
                        <Box>
                            <SqlQueryEditor reportSegment={tempSegment} onSegmentChange={(e:iReportSegment) => {setTempSegment(e);}} />
                        </Box>
                        <Box>
                            <Button variant="outlined" onClick={()=>{
                                saveReportSegment();
                            }}>
                                Save
                            </Button>
                        </Box>

                    </Stack>
                </Box>
                <Box style={{width: "60%"}}>
                    <ReportSegmentPreview reportSegment={tempSegment} />
                </Box>
        </Stack>
    </Container>
    }