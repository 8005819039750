import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { TestReportResult } from "api/Api";

export function ReportResults({reportResults}: {reportResults:TestReportResult}){
    
    return <div>
        <Table>
            <TableHead>
                <TableRow>
                    {Object.keys(reportResults.results[0]).map((header, i) => <TableCell key={i}>{header}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {reportResults.results.map((row, i) => <TableRow key={i}>
                    {Object.entries(row).map(([key, cell], j) => <TableCell key={j}>{cell}</TableCell>)}
                </TableRow>)}
            </TableBody>
        </Table>
        {/* <p>Records: {reportResults && (reportResults?.results?.length ?? '')}</p>
        <p>Execution Time: {reportResults && ((reportResults?.timing ?? 0)/1000)}</p> */}
        </div>;
}