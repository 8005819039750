import { Box, Stack, Table, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { round } from "lodash";
import apiService from "services/apiService";

import "./QuerySpeedReport.css"
import { CopyToClipboard } from "components/common/CopyToClipboard";

export function QuerySpeedReport() {
    const [querySpeedData, setQuerySpeedData] = useState<any[]>([]);

    const [sortColumn, setSortColumn] = useState<string>("avg_time");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

    const sortColumnHandler = (column: string) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortDirection("desc");
        }
    };

    useEffect(() => {
        apiService
        .getApi()
        .reference
        .referenceControllerQuerySpeedReport()
        .then((response: any) => {
            setQuerySpeedData(response.data);
        });
        
    }, []);

    useEffect(() => {
        if (querySpeedData.length > 0) {
            // if the sort column is a number, sort it as a number
            if(querySpeedData[0][sortColumn] && typeof querySpeedData[0][sortColumn] === "number") {
            setQuerySpeedData(querySpeedData.sort((a, b) => {
                if (sortDirection === "asc") {
                    return a[sortColumn] - b[sortColumn];
                } else {
                    return b[sortColumn] - a[sortColumn];
                }
            }));
            // strings!
            } else {
                setQuerySpeedData(querySpeedData.sort((a, b) => {
                    if (sortDirection === "asc") {
                        return a[sortColumn].localeCompare(b[sortColumn]);
                    } else {
                        return b[sortColumn].localeCompare(a[sortColumn]);
                    }
                }));
            }
        }
    }, [querySpeedData, sortColumn, sortDirection]);

    return <Box width={"98%"}>
                <Stack direction="column">
                    <Box><Typography fontWeight="bold" fontSize="1.1rem" marginBottom="0.5em">Query Speed Report</Typography></Box>
                    <Box>
                        <Table className="query-speed-report">
                            <thead>
                                <tr>
                                    <th style={{textAlign: "left"}} onClick={()=>{sortColumnHandler('Query')}}>
                                        Query
                                        {
                                            ' '
                                        }
                                        {
                                            sortColumn === "Query" &&
                                            (sortDirection === "asc" ? <span className="sort-direction-asc">&#9650;</span> : <span className="sort-direction-desc">&#9660;</span>)
                                        }
                                    </th>
                                    <th style={{textAlign: "right"}} title="Number of Executions" onClick={()=> {sortColumnHandler('num_executions');} }>
                                        Num. Exec.
                                        {
                                            ' '
                                        }
                                        {
                                            sortColumn === "num_executions" &&
                                            (sortDirection === "asc" ? <span className="sort-direction-asc">&#9650;</span> : <span className="sort-direction-desc">&#9660;</span>)
                                        }
                                    </th>
                                    <th style={{textAlign: "right"}} onClick={()=> {sortColumnHandler('avg_time');}}>
                                        Avg. Time
                                        {
                                            ' '
                                        }
                                        {
                                            sortColumn === "avg_time" &&
                                            (sortDirection === "asc" ? <span className="sort-direction-asc">&#9650;</span> : <span className="sort-direction-desc">&#9660;</span>)
                                        }
                                    </th>
                                    <th style={{textAlign: "right"}} onClick={()=> {sortColumnHandler('last_run');}}>
                                        Last Run
                                        {
                                            ' '
                                        }
                                        {
                                            sortColumn === "last_run" &&
                                            (sortDirection === "asc" ? <span className="sort-direction-asc">&#9650;</span> : <span className="sort-direction-desc">&#9660;</span>)
                                        }
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    querySpeedData.map((row, index) => {
                                        return <tr key={index}>
                                            <td title={row.Query}>
                                            {
                                            ' '
                                        }
                                                <CopyToClipboard valueToCopy={row.Query}/>{row.Query}
                                            </td>
                                            <td>{row.num_executions}</td>
                                            <td>{round(row.avg_time, 1)}</td>
                                            <td>{row.last_run}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </Box>
                </Stack>
            </Box>
}