import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Select } from "components/mui/Select";
import { EmailableReport } from "api/Api";
import { Input } from "components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setAppToast } from "redux/reducers/appSlice";
import { newToast } from "services";
import apiService from "services/apiService";
import { ReportSegmentPicker } from "./ReportSegmentPicker/ReportSegmentPicker";
import { ReportPreview } from "./ReportPreview/ReportPreview";
import { useDashboardReports } from 'hooks/email/useDashboardReports';

export function Report({reportType = "email"}: {reportType: "email" | "dashboard";}) {
    const params = useParams()
    const { id: idParam } = params;
    const dispatch = useDispatch();
    const [id, setId] = useState(typeof idParam === "string" ? parseInt(idParam) : idParam ?? 0);
    const [linkedToReport, setLinkedToReport] = useState<EmailableReport | undefined>(undefined);
    const [dashboardOptions, loadingDashboardOptions] = useDashboardReports();
    
    const [tempReport, setTempReport] = useState<EmailableReport>({
        id: 0,
        reportName: "",
        reportType,
        reportDescription: "",
        preamble: "",
        segments: [],
    } as EmailableReport);

    useEffect(() => {
        if(id === 0){
            return;
        }
        apiService.getApi()
        .admin
        .emailReportControllerGetEmailableReport(id)
        .then((response) => {
            setTempReport(response.data);
        });
    }, [id]);

    function saveEmailReport() {
        apiService.getApi()
        .admin
        .emailReportControllerSetReport(tempReport.id, tempReport)
        .then((response) => {
            setId(response.data.id);
            dispatch(setAppToast(newToast("success", "Report Saved")));
        })
    }

    return <Container>
            <Box>
                <h1>{tempReport.reportName && tempReport.reportDescription.length > 0 ? tempReport.reportName : "New Report"}</h1>
            </Box>
            <Stack direction="row">
                <Stack width="40%" direction="column" spacing={2} paddingRight={4}>
                <Box>
                    <Typography variant="h4">Edit {reportType === "email"?"Email":"Dashboard"} Report</Typography>
                </Box>
                    
                <Box padding={2}>
                    <WarningAmberIcon />
                    <Typography variant="caption">This tool is presently not checking to make sure you don't email HIPAA compliant information. Don't do that.</Typography>
                </Box>

                <Box>
                    <Input 
                        autoFocus={true}
                        label="Report Name" 
                        value={tempReport?.reportName} 
                        onChange={(e) => {
                            setTempReport({
                                ...tempReport,
                                reportName: e.target.value
                            });
                        
                        }}
                        fullWidth={true}
                        />
                </Box>

                <Box>
                    <Input 
                        label="Administrative Description (not used in report)" 
                        value={tempReport?.reportDescription} 
                        multiline={true}
                        minRows={3}
                        maxRows={3}
                        onChange={(e) => {
                        setTempReport({
                            ...tempReport,
                            reportDescription: e.target.value
                        });
                    }} />
                </Box>

                <Box>
                    <Input 
                        multiline={true}
                        label="Report Preamble (used at top of report)"
                        value={tempReport?.preamble} 
                        onChange={(e) => {
                        setTempReport({
                            ...tempReport,
                            preamble: e.target.value
                        });
                    }} />
                </Box>

                {
                    reportType === "email" && <Box>
                        {loadingDashboardOptions && <>Hold on... loading.</>}
                        {!loadingDashboardOptions &&<Select
                            label="Links To Dashboard (optional)" 
                            value={linkedToReport} 
                            options={dashboardOptions}
                            getOptionLabel={(dashboardOption: EmailableReport) => {
                                return dashboardOption.reportName;
                            }}
                            onChange={(e, value:EmailableReport) => {
                            setTempReport({
                                ...tempReport,
                                linksTo: value.id
                            });
                            setLinkedToReport(value)
                        }} />}
                </Box>
                }

                <ReportSegmentPicker 
                    segments={tempReport.segments ?? []} 
                    onSegmentPickerChange={(segs) => {
                        setTempReport({
                            ...tempReport,
                            segments: segs
                        });
                    }} 
                    reportType={reportType}
                    />

                <Box>
                    <Button variant="outlined" onClick={
                        () => {
                            saveEmailReport();
                        }
                    }>Save</Button>
                </Box>
            </Stack>
            
            <Stack width="60%" direction="column" spacing={2}>
                <Box>
                    <Typography variant="h4">Preview</Typography>
                </Box>
                <Box>
                    <ReportPreview report={tempReport} />
                </Box>
            </Stack>
        </Stack>
    </Container>;
}