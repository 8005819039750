import { Stack } from "components";

import { DbtTable } from "api/Api";
import produce from "immer";
import { useContext } from "react";
import { getSpacing } from "theme";
import { ReportContext } from "../ReportContextProvider";
import { addTableToTree, getArrayFromTableTree, removeTableFromTree, treeHasTable } from "../utils/table-tree-utils";
import { Table } from "./Table/Table";

export const SelectedTab = () => {
  const reportContext = useContext(ReportContext);
  const tableTree = reportContext.queryRequest.tableTree;
  const selectedTables = getArrayFromTableTree(tableTree);
  const selectedFields = reportContext.queryRequest.fields ?? [];
  const joinMaps = reportContext.joinMaps;

  return (
    <Stack py={getSpacing("md")} spacing="sm">
      {selectedTables.map((selectedTable) => {
        const dbtTable = reportContext.getTable(selectedTable.dbName,selectedTable.name) || ({} as DbtTable);

        return (
          <Table
            key={`${selectedTable.dbName}.${selectedTable.name}`}
            dbtTable={dbtTable}
            selectedFields={selectedFields.filter((x) => x.dbName === dbtTable.dbName?.toLowerCase() &&  x.tableName.toLowerCase() === dbtTable.name?.toLowerCase())}
            setSelectedFields={(selectedFields) => {
              reportContext.setQueryRequest(
                produce(reportContext.queryRequest, (draft) => {
                  if (!treeHasTable(draft.tableTree, dbtTable)){
                    draft.tableTree = addTableToTree(draft.tableTree, dbtTable, joinMaps);
                  }

                  draft.fields = reportContext.mergeFields(draft.fields!, dbtTable.name, selectedFields);
                })
              );
            }}
            removeTable={() => {
              reportContext.setQueryRequest(
                produce(reportContext.queryRequest, (draft) => {
                  draft.tableTree = removeTableFromTree(draft.tableTree, dbtTable);
                })
              );
            }}
          />
        );
      })}
    </Stack>
  );
};