import { DbtTable } from "api/Api";
import { Select } from "components";

export const FilterTableInput = (props: {
  dbtTables: DbtTable[];
  selectedDbtTable: DbtTable | undefined;
  setSelectedDbtTable: (value: DbtTable | undefined) => void;
}) => {
  return (
    <Select
      label="Table"
      value={props.selectedDbtTable}
      options={props.dbtTables}
      getOptionLabel={(option) => option.displayName!}
      onChange={
        (e, value) => props.setSelectedDbtTable(value)
        // props.setSelectedDbtTable(
        //   produce(props.filter, (draft) => {
        //     draft.field = { dbName: value?.dbName, tableName: value?.name, fieldName: "" };
        //     draft.values = [];
        //   })
        // );
      }
      size="small"
      noOptionsText={props.dbtTables.length === 0 && "No selectable tables"}
      autoComplete="off"
      disableClearable
    />
  );
};
