import { Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, Stack, TextField, Typography, createStyles, makeStyles } from "@mui/material";
import { createRef, useState } from "react";
import { getFileSafeDate, getUIDateFormat } from "utils";
import PolicyIcon from '@mui/icons-material/Policy';
import DownloadIcon from '@mui/icons-material/Download';
import apiService from "services/apiService";

const defaultStartOfMonth = new Date();
defaultStartOfMonth.setDate(1);

export function UploaderControl({humanReadableName, dbName, tableName, onUploadComplete}: {humanReadableName: string;dbName: string; tableName: string; onUploadComplete:Function;}) {
    const fileRef = createRef<HTMLInputElement>();
    const [fileReadyToUpload, setFileReadyToUpload] = useState<boolean>(false);
    const [isActiveSpreadsheet, setIsCurrentSpreadsheet] = useState<boolean>(true);
    const [effectiveStartDate, setEffectiveStartDate] = useState<string>(getFileSafeDate(defaultStartOfMonth));
    const [effectiveEndDate, setEffectiveEndDate] = useState<string|undefined>(getFileSafeDate(new Date()));

    function downloadTemplate() {
        apiService
        .getApi()
        .fileUploads
        .financeControllerDownloadSpreadsheetTemplate(dbName, tableName)
        .then(async (response) => {
            const fileName = `${tableName}-${getFileSafeDate(new Date())}.xlsx`;

            const blob = await response.blob();

            // create file link in browser's memory
            const href = URL.createObjectURL(blob);
            // create "a" HTML element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch(error => {
            console.error(`Error downloading ${humanReadableName} spreadsheet template`, error);
        });
    }

    function uploadFile(file: any) {
        const params = {
            startDate: effectiveStartDate,
            excelFile: file
        };
        if(!isActiveSpreadsheet){
            // hack because types are hard
            (params as any).endDate = effectiveEndDate;
        }
        apiService
        .getApi()
        .fileUploads
        .financeControllerUploadData(dbName, tableName, params)
        .then(response => {
            console.log(`${humanReadableName} spreadsheet uploaded`, response);
            if(onUploadComplete){
                onUploadComplete();
            }
        }).catch(error => {
            console.error(`Error uploading ${humanReadableName} spreadsheet`, error);
        });
    }

    return <Card variant="outlined">
    <CardHeader title={`${humanReadableName} Spreadsheet Uploads`} />
    <Divider />
    <CardContent>
        <Stack direction="row" spacing={2}>
            <Box sx={{width: "75%"}}>
                <Typography variant="h6">Upload {humanReadableName} Spreadsheet</Typography>
                <Stack direction="row" spacing="2" sx={{margin: 4}}>
                    <Box sx={{width:"25%"}}>
                        <Stack direction="column" spacing={2}>
                            <Box>
                            <Typography variant="caption">1. Select the start date of the data</Typography>
                            </Box>
                            <Box>
                            <TextField 
                                type="date" 
                                label="Start Date" 
                                value={effectiveStartDate}
                                onChange={(e)=>{
                                    setEffectiveStartDate(e.target.value);
                                }} 
                                />
                                </Box>
                        </Stack>
                    </Box>
                    <Box sx={{width:"25%"}}>
                        <Stack direction="column" spacing={2}>
                            <Box>
                                <Typography variant="caption">2. Select the end date of the data</Typography>
                            </Box>
                            <Box>
                                <TextField 
                                    type="date" 
                                    label="End Date" 
                                    value={effectiveEndDate} 
                                    onChange={(e)=>{
                                        setEffectiveEndDate(e.target.value);
                                    }}
                                    disabled={isActiveSpreadsheet}
                                    />
                            </Box>
                            <Box>
                                <label>Active Spreadsheet</label>
                                <Checkbox title="Active Spreadsheet" value="current" checked={isActiveSpreadsheet} onChange={(e)=>{
                                    setIsCurrentSpreadsheet(e.target.checked);
                                }}/>
                            </Box>
                        </Stack>
                    </Box>
                    <Box sx={{width:"25%"}}>
                        <Stack direction="column" spacing={2}>
                            <Box>
                                <Typography variant="caption">3. Choose the file to upload</Typography>
                            </Box>
                            <Box>
                                <input
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        setFileReadyToUpload(file ?true:false);                        
                                    }} 
                                    ref={fileRef} 
                                    type="file" 
                                    name="file" 
                                    accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                    />
                            </Box>
                        </Stack>
                    </Box>
                    <Box sx={{width:"25%"}}>
                        <Stack direction="column" spacing={2}>
                            <Box>
                                <Typography variant="caption">4. Upload the file</Typography>
                            </Box>
                            <Box>
                        <Button 
                        variant="contained"
                        onClick={(e)=>{ fileRef.current?.files?.[0] && uploadFile(fileRef.current.files[0]); }}
                        disabled={!fileReadyToUpload}
                        >Upload File</Button>
                        <p>
                        {
                            isActiveSpreadsheet ?
                                `Will be active for upcoming reports` : 
                                `Will be effective for reports from ${getUIDateFormat(effectiveStartDate)} to ${getUIDateFormat(effectiveEndDate ?? new Date())}`
                        }</p>
                        </Box>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Box sx={{width: "22%"}}>
                <Box>
                    <PolicyIcon style={{color: "#EDD140",border: "1px solid #EDD140", borderRadius: "4px", padding: "0.125rem", width: "2.5rem", height: "2.5rem", marginRight: "0.5rem", marginBottom: "0.25rem", float: "left"}} /> 
                    When you upload the spreadsheet to the system,
                    the system will automatically update the {humanReadableName} information 
                    based on what you upload.  Please make sure that the format 
                    matches the template.
                </Box>
                <Box textAlign="center">
                    <Button variant="outlined" onClick={()=>{ downloadTemplate(); }} style={{marginTop: "1.5rem"}}>
                        <DownloadIcon />
                        Download {humanReadableName} Template
                    </Button>
                </Box>
            </Box>    
        </Stack>
    </CardContent>
</Card>
}