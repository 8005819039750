import { Employee } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function usePotentialReaders(roll: string | undefined): [Employee[], boolean] {
  const [readers, setReaders] = useState<Employee[]>([]);
  const [filteredReaders, setFilteredReaders] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    apiService
      .getApi()
      .admin.emailReportControllerGetPotentialEmployees()
      .then((response) => {
        setReaders(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setFilteredReaders(
      !roll ? readers : readers.filter((reader) => roll.toLowerCase() === reader.job_title.toLowerCase())
    );
  }, [roll, readers]);

  return [filteredReaders, loading];
}
