import { createSlice } from "@reduxjs/toolkit";
import { UserSettingDto } from "api/Api";

export type UserSettingPageSizeOption = 100 | 500 | 1000;
export const userSettingPageSizeOptions: UserSettingPageSizeOption[] = [100, 500, 1000];

interface UserSettingState {
  navPinned: boolean;
  navWidth: number;
  pageSize: UserSettingPageSizeOption;
}

const initialState: UserSettingState = {
  navPinned: false,
  navWidth: 250,
  pageSize: userSettingPageSizeOptions[0],
};

export const userSettingSlice = createSlice({
  name: "userSetting",
  initialState,
  reducers: {
    setUserSettingInitialState: (state, action: { type: string; payload: UserSettingDto }) => {
      state.navPinned = action.payload.navPinned;

      if (
        action.payload.pageSize &&
        userSettingPageSizeOptions.includes(Number(action.payload.pageSize) as UserSettingPageSizeOption)
      ) {
        state.pageSize = Number(action.payload.pageSize) as UserSettingPageSizeOption;
      }

      if (action.payload.navWidth) state.navWidth = action.payload.navWidth;
    },
    setUserSettingNavPinned: (state, action: { type: string; payload: typeof initialState.navPinned }) => {
      state.navPinned = action.payload;
    },
    setUserSettingNavWidth: (state, action: { type: string; payload: typeof initialState.navWidth }) => {
      state.navWidth = action.payload;
    },
    setUserSettingPageSize: (state, action: { type: string; payload: typeof initialState.pageSize }) => {
      state.pageSize = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserSettingInitialState, setUserSettingNavPinned, setUserSettingPageSize } = userSettingSlice.actions;

export default userSettingSlice.reducer;
