import { Filter as FilterModel } from "api/Api";
import { Button, Card, Collapse, Stack } from "components";
import produce from "immer";
import { Filter } from "./Filters/Filter/Filter";
import { getArrayFromTableTree } from "./utils/table-tree-utils";
import { useContext } from "react";
import { ReportContext } from "./ReportContextProvider";

export const Filters = (props: { open: boolean }) => {
  const reportContext = useContext(ReportContext);
  const queryRequest = reportContext.queryRequest;
  const tableTreeArray = getArrayFromTableTree(reportContext?.queryRequest?.tableTree);

  return (
    <Collapse in={props.open}>
      <Card header="Filters">
        <Stack spacing="lg">
          <Stack spacing="sm">
            {queryRequest.filters?.map((filter, index) => {
              return (
                <Filter
                  key={index}
                  filter={filter}
                  setFilter={(filter) =>
                    reportContext.setQueryRequest(
                      produce(queryRequest, (draft) => {
                        draft.filters![index] = filter;
                      })
                    )
                  }
                  removeFilter={() =>
                    reportContext.setQueryRequest(
                      produce(queryRequest, (draft) => {
                        draft.filters!.splice(index, 1);
                      })
                    )
                  }
                />
              );
            })}
          </Stack>

          <Stack direction="row">
            <Button
              variant="outlined"
              onClick={() => {
                const filters = produce(queryRequest, (draft) => {
                  draft.filters!.push({
                    active: true,
                    field: {
                      dbName: tableTreeArray.length === 1 ? tableTreeArray[0].dbName : undefined,
                      // prepopulate table if there is only one table in the table tree
                      tableName: tableTreeArray.length === 1 ? tableTreeArray[0].name : undefined,
                    },
                    operator: "is",
                  } as FilterModel);
                });
                reportContext.setQueryRequest(filters);
              }}
            >
              + Custom Filter
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Collapse>
  );
};
