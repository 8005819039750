import { Box, Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";


export function EmailManagementDashboard() {
    return (<Container>
        <Stack direction="column" spacing="md">
            <Box>
                <Typography variant="h3">Email Management</Typography>
            </Box>
            <Box>
                To build email reports you will need to configure lists of recipients, reports, and the segments (or sections) of those reports that you want to include in the email.
            </Box>
            <Box>
                Manage the lists <Link to={`/admin/email-management/lists`}>here</Link>.
            </Box>
            <Box>
                Manage the reports <Link to={`/admin/email-management/reports`}>here</Link>.
            </Box>
            <Box>
                Manage the segments of a report <Link to={`/admin/email-management/segments`}>here</Link>.
            </Box>
        </Stack>
    </Container>);
}