import { Menu, MenuItem, Stack, TableCell, Typography } from "components";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { Field, SortField } from "api/Api";
import produce from "immer";
import { useContext, useEffect, useState } from "react";
import { ReportContext } from "../ReportContextProvider";
import { getFieldFullIdentifier } from "../utils/utils";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import GroupWorkIcon from '@mui/icons-material/GroupWork';

export const TableHeaderCell = (props: {
  field: Field;
  sortFields: SortField[];
  setSortFields: (sortFields: SortField[]) => void;
  remove: () => void;
  draggableProps: any;
  dragInnerRef: any;
  dragHandleProps: any;
  onDragMouseDown: (e: React.MouseEvent<HTMLButtonElement>) => void;
  colSpan?: number;
  rowSpan?: number;
}) => {
  const reportContext = useContext(ReportContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const displayNode = reportContext.getFieldDisplay(props.field, "node", { nodeDescriptionTooltip: true });
  const displayName = reportContext.getFieldDisplay(props.field, "string");
  const [hovered, setHovered] = useState(false);


  const sort = (desc: boolean) => {
    const sortFields = new Map(
      props.sortFields.map((sortField) => [getFieldFullIdentifier(sortField.field), sortField])
    );

    sortFields.set(getFieldFullIdentifier(props.field), { field: props.field, desc: desc });
    props.setSortFields([...sortFields.values()]);
  };

  const isSorting = (desc?: boolean) => {
    const sortField = props.sortFields.find(
      (sortField) => getFieldFullIdentifier(sortField.field) === getFieldFullIdentifier(props.field)
    );

    if (desc === undefined && sortField !== undefined) return true;
    if (desc !== undefined && sortField?.desc === desc) return true;
    return false;
  };

  const getSortOrder = () => {
    return (
      props.sortFields.findIndex(
        (sortField) => getFieldFullIdentifier(sortField.field) === getFieldFullIdentifier(props.field)
      ) + 1
    );
  };

  const removeSort = () => {
    props.setSortFields(
      produce(props.sortFields, (draft) => {
        return draft.filter(
          (sortField) => getFieldFullIdentifier(sortField.field) !== getFieldFullIdentifier(props.field)
        );
      })
    );
  };

  return (
    <>
      <TableCell
        ref={props.dragInnerRef}
        {...props.draggableProps}
        sx={{
          backgroundColor: (theme) => theme.palette.grey.A200,
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        colSpan={props.colSpan}
        rowSpan={props.rowSpan}
      >
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} spacing="sm">
          <Stack direction="row" alignItems="center" spacing="xs">
            {displayNode}

            {isSorting() && (
              <Stack direction="row" alignItems="center" sx={{ opacity: 0.5 }}>
                {isSorting(false) && <ArrowUpwardIcon sx={{ fontSize: "13px" }} />}
                {isSorting(true) && <ArrowDownwardIcon sx={{ fontSize: "13px" }} />}

                {props.sortFields.length > 1 && <Typography variant="inherit">{getSortOrder()}</Typography>}
              </Stack>
            )}
          </Stack>

          <Stack direction="row">
            <Tooltip
              sx={{ visibility: hovered ? "visible" : "hidden" }}
              title="Drag and drop this column to reorder"
              placement="top"
            >
              <IconButton {...props.dragHandleProps} size="small" color="inherit" onMouseDown={props.onDragMouseDown}>
                <DragIndicatorIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip
              sx={{ visibility: hovered || Boolean(menuAnchorEl) ? "visible" : "hidden" }}
              title="Column Options"
              placement="top"
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setMenuAnchorEl(e.currentTarget);
                }}
                size="small"
                color="inherit"
              >
                <ArrowDropDownIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Stack>

          {/* <HeaderCellMenu menuAnchorEl={headerCellMenuAnchorEl} /> */}
        </Stack>
      </TableCell>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            sort(false);
            setMenuAnchorEl(null);
          }}
          disabled={isSorting(false)}
        >
          <Stack direction="row" alignItems="center" spacing="sm">
            <ArrowUpwardIcon fontSize="small" />
            <Typography variant="inherit">Sort: ASC</Typography>
            {isSorting(false) && <CheckIcon fontSize="small" color="success" />}
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            sort(true);
            setMenuAnchorEl(null);
          }}
          disabled={isSorting(true)}
        >
          <Stack direction="row" alignItems="center" spacing="sm">
            <ArrowDownwardIcon fontSize="small" />
            <Typography variant="inherit">Sort: DESC</Typography>
            {isSorting(true) && <CheckIcon fontSize="small" color="success" />}
          </Stack>
        </MenuItem>

        {isSorting() && (
          <MenuItem
            onClick={(e: React.MouseEvent<HTMLLIElement>) => {
              e.stopPropagation();
              removeSort();
              setMenuAnchorEl(null);
            }}
          >
            <Stack direction="row" alignItems="center" spacing="sm">
              <RemoveIcon fontSize="small" />
              <Typography variant="inherit">Remove Sort</Typography>
            </Stack>
          </MenuItem>
        )}

        <Divider />

        <MenuItem
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();

            props.remove();
            setMenuAnchorEl(null);
          }}
        >
          <Stack direction="row" alignItems="center" spacing="sm">
            <DeleteIcon color={"error"} />
            <Typography color="error">Remove Field</Typography>
          </Stack>
        </MenuItem>

      </Menu>
    </>
  );
};
