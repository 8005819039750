import { BaseLayout, NavLayout, PublicLayout } from "layouts";
import { AdminPage, adminPageRoute } from "pages/Admin/Admin";
import { reportPageRoute } from "pages/ReportPage/ReportPage";
import { loginPageRoute } from "pages/LoginPage/LoginPage";
import { createPresetPageRoute } from "pages/presets/CreatePresetPage/CreatePresetPage";
import { presetListPageRoute } from "pages/presets/PresetListPage/PresetListPage";
import { updatePresetPageRoute } from "pages/presets/UpdatePresetPage/UpdatePresetPage";
import { testPageRoute } from "pages/TestPage/TestPage";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { AuthCheck } from "./AuthCheck";
import { lazyLoadingComponents } from "./components";
import { StatsPage, statsPageRoute } from "pages/Stats/StatsPage";
import { TurnoverDashboardPage, terminationDashboardPageRoute } from "pages/Turnover/TurnoverDashboardPage";
import { CustomReportPageRoutes } from "pages/Custom-Reports/Reports";
import { financeDashboardPageRoute } from "pages/Finance/FinanceDashboard";
import { Missing404Layout } from "pages/Errors/404/Missing404Layout";


// Component Pages.
// Avoiding importing all components / import component as we need
// By declaring each component as lazy promise so that it gets imported on the spot
// only when that component is being routed to
const { TestPage, LoginPage, ReportPage, PresetListPage, CreatePresetPage, UpdatePresetPage } =
  lazyLoadingComponents();

// const HomePage = Loadable(lazy(() => import("pages/home/HomePage")));
// const LoginPage = Loadable(lazy(() => import("pages/login/LoginPage")));

// copies RouteObject from react-router-dom, with additional field
// export type RouteObject = {
//   children?: RouteObject[];
//   element?: React.ReactNode;
//   index?: boolean;
//   path?: string;
//   name?: string;
// };

const publicRoutes: RouteObject[] = [
  {
    path: loginPageRoute.templatePath,
    handle: loginPageRoute.handle,
    element: <LoginPage />,
  },
];

const navRoutes: RouteObject[] = [
  { element: <Navigate to={reportPageRoute.templatePath} replace />, index: true }, // default
  {
    path: reportPageRoute.templatePath,
    handle: reportPageRoute.handle,
    element: <ReportPage />,
    children: [
      {
        path: presetListPageRoute.templatePath,
        handle: presetListPageRoute.handle,
        element: <PresetListPage />,
        children: [
          {
            path: updatePresetPageRoute.templatePath,
            handle: updatePresetPageRoute.handle,
            element: <UpdatePresetPage />,
          },
        ],
      },
      {
        path: createPresetPageRoute.templatePath,
        handle: createPresetPageRoute.handle,
        element: <CreatePresetPage />,
      },
    ],
  },
  {
    path: testPageRoute.templatePath,
    handle: testPageRoute.handle,
    element: <TestPage />,
  },
  adminPageRoute,
  {
    path: CustomReportPageRoutes.templatePath,
    handle: CustomReportPageRoutes.handle,
    element: CustomReportPageRoutes.element,
    children: CustomReportPageRoutes.children,
  },
  {
    path: statsPageRoute.templatePath,
    handle: statsPageRoute.handle,
    element: <StatsPage />,
  },
  {
    path: terminationDashboardPageRoute.path,
    handle: terminationDashboardPageRoute.handle,
    element: <TurnoverDashboardPage />,
  },
  {
    path: financeDashboardPageRoute.path,
    handle: financeDashboardPageRoute.handle,
    element: financeDashboardPageRoute.element,
    children: financeDashboardPageRoute.children,
  }
];

export const appRoutes: RouteObject[] = [
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: (
          <AuthCheck>
            <NavLayout />
          </AuthCheck>
        ),
        children: navRoutes,
      },
      // PublicLayout
      {
        path: "/",
        element: <PublicLayout />,
        children: publicRoutes,
      },
      {
        path: "404",
        element: <Missing404Layout />,
      },
    ],
  },

  { path: "*", element: <Navigate to="/404" replace /> },
];

export function AppRoutes() {
  // @ts-ignore
  return useRoutes(appRoutes);
}
