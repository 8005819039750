/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface FieldOutput {
  dataType: "String" | "Number" | "Boolean" | "Date" | "Datetime" | "Time";
  numberPrecision?: number;
  context?: "HTML" | "Currency" | "Short_Date" | "Long_Date";
}

export interface SwaggerModels {
  fieldOutput: FieldOutput;
}

export interface EmployeeEmail {
  firstName: string;
  lastName: string;
  jobTitle: string;
  departmentName: string;
  location: string;
  email: string;
}

export interface EmailListSearch {
  searchString?: string;
  active?: boolean;
  page?: number;
  pageSize?: number;
}

export interface EmailList {
  id: number;
  subjectPrefix?: string;
  name: string;
  description: string;
  forRoles: string[];
  sendOnlyOnScheduledDays: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  frequency: string;
  dayOfWeek?: number;
  dayOfMonth?: number;
  timeOfDay?: string;
  subscribers: string[];
  reportId?: number;
  reportName?: string;
}

export interface ReportSegmentVariablesMap {
  id: number;
  segmentId: number;
  label: string;
  queryVariableName: string;
  parameterVariableName: string;
}

export interface ColumnMap {
  id: number;
  segmentId: number;
  dbName: string;
  tableName: string;
  columnName: string;
  displayName: string;
}

export interface ReportSegment {
  id: number;
  name: string;
  description: string;
  type: 0 | 1;
  sqlQuery: string;
  reactTemplate: string;
  requiredVariables?: ReportSegmentVariablesMap[];
  columnsMap?: ColumnMap[];
}

export interface EmailableReport {
  id: number;
  reportName: string;
  reportDescription: string;
  preamble: string;
  reportUrl?: string;
  linksTo?: number;
  reportType: "email" | "dashboard";
  segments?: ReportSegment[];
}

export interface SendEmailReport {
  report_id: number;
  badge_number: string;
  facility_id: number;
  report_date: string;
}

export interface Employee {
  badge_number: string;
  login_id: string;
  first_name: string;
  last_name: string;
  status_shortname: string;
  job_title: string;
  pay_class: string;
  email: string;
  department_name: string;
  facility_id: number;
  location_longname: string;
  maplocation_longname: string;
  entity: string;
  primary_location: string;
  hiredate: string;
  original_hiredate: string;
  performance_rating_score: number;
  termination_date: string;
  eligible_rehire: string;
  was_terminated: string;
  was_voluntary: string;
}

export interface TestReportQuery {
  query: string;
  userId?: number;
  facilityId?: string;
  reportDate?: string;
}

export interface TestReportResult {
  timing: number;
  results: any[][];
}

export interface EncounterStub {
  id: number;
  datetime: string;
  duration: string;
  type: string;
  status: string;
}

export interface Staff {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  job_title: string;
  encounters?: EncounterStub[];
}

export interface Facility {
  id: number;
  name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  fax: string;
  staff: Staff[];
}

export interface FileUploadRequest {
  startDate: string;
  endDate?: string;
  /** @format binary */
  excelFile: File;
}

export interface FinanceUploadResponse {
  insertTotal: number;
  elapsedTime: number;
}

export interface LastUpdatedData {
  dbName: string;
  tableName: string;
  lastUpdated: string;
  updatedBy: string;
}

export interface Role {
  id: string;
  name: string;
  description: string;
}

export interface AdminLink {
  name: string;
  href: string;
  icon: string;
  role: Role;
}

export interface DbtDataField {
  dbName: string;
  tableName: string;
  fieldName: string;
  displayName: string;
  dataType: "String" | "Number" | "Boolean" | "Date" | "Datetime" | "Time";
  dbDataType: string;
  context?: "HTML" | "Currency" | "Short_Date" | "Long_Date";
  tableDisplayName?: string;
  numberPrecision?: number;
}

export interface DbtTable {
  dbName: string;
  name: string;
  displayName: string;
  dataFields: DbtDataField[];
}

export interface JoinMap {
  nativeDbName: string;
  nativeTable: string;
  nativeTableDisplayName: string;
  nativeColumn: string;
  nativeColumnDisplayName: string;
  foreignDbName: string;
  foreignTable: string;
  foreignTableDisplayName: string;
  foreignColumn: string;
  foreignColumnDisplayName: string;
}

export interface LookupDto {
  dbName: string;
  table: string;
  column: string;
  values: string[];
}

export interface FilterOperatorOption {
  operator:
    | "is"
    | "is_not"
    | "is_greater_than"
    | "is_greater_than_or_equals"
    | "is_less_than"
    | "is_less_than_or_equals"
    | "is_after"
    | "is_after_relative"
    | "is_on_or_after"
    | "is_on_or_after_relative"
    | "is_before"
    | "is_before_relative"
    | "is_on_or_before"
    | "is_on_or_before_relative"
    | "is_between"
    | "contains"
    | "does_not_contain"
    | "starts_with"
    | "ends_with"
    | "is_null"
    | "is_not_null";
  name: string;
  applicableFieldTypes: ("String" | "Number" | "Boolean" | "Date" | "Datetime" | "Time")[];
}

export enum FilterRelativeDateValueUnit {
  Days = "Days",
  Weeks = "Weeks",
  WeeksCalendar = "Weeks_Calendar",
  Months = "Months",
  MonthsCalendar = "Months_Calendar",
  Years = "Years",
  YearsCalendar = "Years_Calendar",
}

export interface FilterRelativeDateValueUnitOption {
  unit: FilterRelativeDateValueUnit;
  displayName: string;
}

export interface AggregateFunctionOption {
  function: "Count" | "CountDistinct" | "Min" | "Max" | "Avg" | "Sum";
  displayName: string;
  dataTypes: ("String" | "Number" | "Boolean" | "Date" | "Datetime" | "Time")[];
}

export interface CustomFunctionOption {
  function: "Year" | "Month" | "Day" | "Week" | "DayOfWeek" | "Year_Month";
  name: string;
  dataTypes: ("String" | "Number" | "Boolean" | "Date" | "Datetime" | "Time")[];
  canPivot: boolean;
}

export interface IntervalOption {
  interval: "Month" | "Day of Week";
  values: any[][];
}

export interface TableJoinStrategy {
  table: string;
  strategy: string;
  strategyObject: object;
}

export interface ReportResource {
  tables: DbtTable[];
  joinMaps: JoinMap[];
  lookups: LookupDto[];
  filterOperatorOptions: FilterOperatorOption[];
  filterRelativeDateValueUnitOptions: FilterRelativeDateValueUnitOption[];
  aggregateFunctionOptions: AggregateFunctionOption[];
  customFunctionOptions: CustomFunctionOption[];
  intervalOptions: IntervalOption[];
  joinStrategies: TableJoinStrategy[];
}

export interface TableNode {
  dbName: string;
  name: string;
  joinFromField?: string;
  joinToField?: string;
  children?: TableNode[];
}

export interface AggregatePivot {
  field: Field;
  values: string[];
}

export interface Field {
  dbName: string;
  tableName: string;
  fieldName: string;
  transform?: "AggregateFunction" | "CustomFunction";
  customFunction?: "Year" | "Month" | "Day" | "Week" | "DayOfWeek" | "Year_Month";
  aggregateFunction?: "Count" | "CountDistinct" | "Min" | "Max" | "Avg" | "Sum";
  aggregatePivot?: AggregatePivot;
}

export interface Filter {
  active: boolean;
  field: Field;
  operator:
    | "is"
    | "is_not"
    | "is_greater_than"
    | "is_greater_than_or_equals"
    | "is_less_than"
    | "is_less_than_or_equals"
    | "is_after"
    | "is_after_relative"
    | "is_on_or_after"
    | "is_on_or_after_relative"
    | "is_before"
    | "is_before_relative"
    | "is_on_or_before"
    | "is_on_or_before_relative"
    | "is_between"
    | "contains"
    | "does_not_contain"
    | "starts_with"
    | "ends_with"
    | "is_null"
    | "is_not_null";
  values: string[];
  nullIncludesEmptyAndWhitespaceString?: boolean;
}

export interface SortField {
  field: Field;
  desc: boolean;
}

export interface QueryRequest {
  tableTree?: TableNode;
  fields: Field[];
  filters?: Filter[];
  sortFields?: SortField[];
  groupByFields?: Field[];
  seriesInterval?: string;
  page: number;
  pageSize: number;
}

export interface QueryResult {
  elapsedTime: number;
  dataRows: string[];
}

export interface QueryTotalResult {
  elapsedTime: number;
  total: number;
}

export interface QuerySqlResult {
  dataSql: string;
  totalSql: string;
}

export interface TerminationReportRequest {
  wasTerminated?: string;
}

export interface TerminationReport {
  monthlyTerminationsByLocation: object;
  monthlyTerminationsByDepartments: object;
  monthlyTerminationsByJobTitle: object;
  monthlyEmployeeSwingsByLocation: object;
  monthlyEmployeeSwingsByDepartment: object;
  monthlyEmployeeSwingsByJobTitle: object;
  monthlyEmployeesAverageByLocation: object;
  monthlyEmployeesAverageByDepartment: object;
  monthlyEmployeesAverageByJobTitle: object;
  monthlyTurnoverRateByLocation: object;
  monthlyTurnoverRateByDepartment: object;
  monthlyTurnoverRateByJobTitle: object;
  yearlyTerminationsByLocation: object;
  yearlyTerminationsByDepartments: object;
  yearlyTerminationsByJobTitle: object;
  yearlyEmployeeSwingsByLocation: object;
  yearlyEmployeeSwingsByDepartment: object;
  yearlyEmployeeSwingsByJobTitle: object;
  yearlyAverageEmployeesByLocation: object;
  yearlyAverageEmployeesByDepartment: object;
  yearlyAverageEmployeesByTitle: object;
  yearlyTurnoverRateByLocation: object;
  yearlyTurnoverRateByDepartment: object;
  yearlyTurnoverRateByJobTitle: object;
}

export interface DateInterval {
  startDate: string;
  endDate: string;
}

export interface DateIntervalResults {
  dbName: string;
  tableName: string;
  dateIntervals: DateInterval[];
}

export interface AllClaimDetailReportRequest {
  startDate: string;
  endDate: string;
}

export type QueryResultDataRow = object;

export interface FinanceReport {
  query: string;
  total: number;
  dataRows: QueryResultDataRow[];
  elapsedTime: number;
}

export interface ReportIntervalDates {
  reportName: string;
  runDate: string;
  startDate: string;
  endDate: string;
  reportRunId: number;
}

export interface GenerateReportRequest {
  reportNames: string[];
  startDate: string;
  endDate: string;
}

export interface PresetListDto {
  id: number;
  presetName: string;
  displayOrder: number;
  createdByID: number;
  createdByName: string;
  /** @format date-time */
  createdDate: string;
  modifiedByID: number;
  modifiedByName: string;
  /** @format date-time */
  modifiedDate: string;
}

export interface PresetDtoTable {
  dbName: string;
  name: string;
}

export interface PresetDto {
  id: number;
  presetName: string;
  public: boolean;
  tables: PresetDtoTable[];
  queryRequest: QueryRequest;
}

export interface PresetReorderDto {
  id: number;
  displayOrder: number;
}

export interface UserDto {
  id: number;
  email: string;
}

export interface UserSettingDto {
  navWidth?: number;
  navPinned: boolean;
  pageSize?: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Ensomata BI Reporter
 * @version 1.0
 * @contact
 *
 * BI Reporter API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name AppControllerGetHello
   * @request OPTIONS:/
   */
  appControllerGetHello = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/`,
      method: "OPTIONS",
      ...params,
    });

  faviconIco = {
    /**
     * No description
     *
     * @name AppControllerGetFavicon
     * @request GET:/favicon.ico
     */
    appControllerGetFavicon: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/favicon.ico`,
        method: "GET",
        ...params,
      }),
  };
  common = {
    /**
     * No description
     *
     * @name CommonControllerSwaggerApiModels
     * @request GET:/common/swaggerApiModels
     */
    commonControllerSwaggerApiModels: (params: RequestParams = {}) =>
      this.request<SwaggerModels, any>({
        path: `/common/swaggerApiModels`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @name EmailControllerGetPotentialSubscribers
     * @request GET:/admin/email-list/potential-subscribers
     */
    emailControllerGetPotentialSubscribers: (params: RequestParams = {}) =>
      this.request<EmployeeEmail[], any>({
        path: `/admin/email-list/potential-subscribers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailControllerPostLists
     * @request POST:/admin/email-list/lists
     */
    emailControllerPostLists: (data: EmailListSearch, params: RequestParams = {}) =>
      this.request<EmailList[], any>({
        path: `/admin/email-list/lists`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailControllerGetList
     * @request GET:/admin/email-list/list/{id}
     */
    emailControllerGetList: (id: number, params: RequestParams = {}) =>
      this.request<EmailList, any>({
        path: `/admin/email-list/list/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailControllerPostList
     * @request POST:/admin/email-list/list/{id}
     */
    emailControllerPostList: (id: number, data: EmailList, params: RequestParams = {}) =>
      this.request<EmailList, any>({
        path: `/admin/email-list/list/${id}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailControllerDeleteList
     * @request GET:/admin/email-list/list/{id}/delete
     */
    emailControllerDeleteList: (id: number, params: RequestParams = {}) =>
      this.request<EmailList, any>({
        path: `/admin/email-list/list/${id}/delete`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailReportControllerGetEmailableReport
     * @request GET:/admin/email-report/emailable-report/{id}
     */
    emailReportControllerGetEmailableReport: (id: number, params: RequestParams = {}) =>
      this.request<EmailableReport, any>({
        path: `/admin/email-report/emailable-report/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailReportControllerDeleteEmailableReport
     * @request DELETE:/admin/email-report/emailable-report/{id}
     */
    emailReportControllerDeleteEmailableReport: (id: number, params: RequestParams = {}) =>
      this.request<EmailableReport, any>({
        path: `/admin/email-report/emailable-report/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailReportControllerSetReport
     * @request POST:/admin/email-report/emailable-report/{id}
     */
    emailReportControllerSetReport: (id: number, data: EmailableReport, params: RequestParams = {}) =>
      this.request<EmailableReport, any>({
        path: `/admin/email-report/emailable-report/${id}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailReportControllerGetEmailableReports
     * @request GET:/admin/email-report/emailable-reports
     */
    emailReportControllerGetEmailableReports: (params: RequestParams = {}) =>
      this.request<EmailableReport[], any>({
        path: `/admin/email-report/emailable-reports`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailReportControllerGetLandingReports
     * @request GET:/admin/email-report/landing-reports
     */
    emailReportControllerGetLandingReports: (params: RequestParams = {}) =>
      this.request<EmailableReport[], any>({
        path: `/admin/email-report/landing-reports`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailReportControllerGetEmailReportHtml
     * @request POST:/admin/email-report/get-email-report/{id}
     */
    emailReportControllerGetEmailReportHtml: (id: string, data: SendEmailReport, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/admin/email-report/get-email-report/${id}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailReportControllerGetPotentialReportSegments
     * @request POST:/admin/email-report/potential-report-segments
     */
    emailReportControllerGetPotentialReportSegments: (params: RequestParams = {}) =>
      this.request<ReportSegment[], any>({
        path: `/admin/email-report/potential-report-segments`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailReportControllerGetPotentialEmployees
     * @request GET:/admin/email-report/potential-employees
     */
    emailReportControllerGetPotentialEmployees: (params: RequestParams = {}) =>
      this.request<Employee[], any>({
        path: `/admin/email-report/potential-employees`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmailReportControllerGetPotentialRolls
     * @request GET:/admin/email-report/potential-rolls
     */
    emailReportControllerGetPotentialRolls: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/admin/email-report/potential-rolls`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportSegmentControllerGetReportSegments
     * @request GET:/admin/report-segments
     */
    reportSegmentControllerGetReportSegments: (params: RequestParams = {}) =>
      this.request<ReportSegment[], any>({
        path: `/admin/report-segments`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportSegmentControllerCreateReportSegment
     * @request POST:/admin/report-segments
     */
    reportSegmentControllerCreateReportSegment: (data: ReportSegment, params: RequestParams = {}) =>
      this.request<ReportSegment, any>({
        path: `/admin/report-segments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportSegmentControllerGetReportSegment
     * @request GET:/admin/report-segments/{id}
     */
    reportSegmentControllerGetReportSegment: (id: number, params: RequestParams = {}) =>
      this.request<ReportSegment, any>({
        path: `/admin/report-segments/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportSegmentControllerPostReportSegment
     * @request PATCH:/admin/report-segments/{id}
     */
    reportSegmentControllerPostReportSegment: (id: number, data: ReportSegment, params: RequestParams = {}) =>
      this.request<ReportSegment, any>({
        path: `/admin/report-segments/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportSegmentControllerDeleteReportSegment
     * @request DELETE:/admin/report-segments/{id}
     */
    reportSegmentControllerDeleteReportSegment: (id: number, params: RequestParams = {}) =>
      this.request<ReportSegment, any>({
        path: `/admin/report-segments/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportSegmentControllerTestReportQuery
     * @request POST:/admin/report-segments/test-report-query
     */
    reportSegmentControllerTestReportQuery: (data: TestReportQuery, params: RequestParams = {}) =>
      this.request<TestReportResult, any>({
        path: `/admin/report-segments/test-report-query`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  employees = {
    /**
     * No description
     *
     * @name EmployeesControllerGetEmployeeByEmail
     * @request GET:/employees/get-employee-by-email/{email}
     */
    employeesControllerGetEmployeeByEmail: (email: string, params: RequestParams = {}) =>
      this.request<Employee, any>({
        path: `/employees/get-employee-by-email/${email}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EmployeesControllerGetEmployeesByLocationId
     * @request GET:/employees/get-employees-by-location-id/{locationId}
     */
    employeesControllerGetEmployeesByLocationId: (locationId: string, params: RequestParams = {}) =>
      this.request<Employee[], any>({
        path: `/employees/get-employees-by-location-id/${locationId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  facilities = {
    /**
     * No description
     *
     * @name FacilitiesControllerGetFacilityById
     * @request GET:/facilities/get-facility/{facilityId}/{date}
     */
    facilitiesControllerGetFacilityById: (facilityId: number, date: string, params: RequestParams = {}) =>
      this.request<Facility, any>({
        path: `/facilities/get-facility/${facilityId}/${date}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  fileUploads = {
    /**
     * No description
     *
     * @name FinanceControllerDownloadSpreadsheetTemplate
     * @request GET:/file-uploads/finance/finance/download-template/{dbName}/{tableName}
     */
    financeControllerDownloadSpreadsheetTemplate: (dbName: string, tableName: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/file-uploads/finance/finance/download-template/${dbName}/${tableName}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name FinanceControllerUploadData
     * @request POST:/file-uploads/finance/finance/upload-data/{dbName}/{tableName}
     */
    financeControllerUploadData: (
      dbName: string,
      tableName: string,
      data: FileUploadRequest,
      params: RequestParams = {}
    ) =>
      this.request<FinanceUploadResponse, any>({
        path: `/file-uploads/finance/finance/upload-data/${dbName}/${tableName}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  reference = {
    /**
     * No description
     *
     * @name ReferenceControllerGetLastUpdated
     * @request GET:/reference/last-updated
     */
    referenceControllerGetLastUpdated: (
      query?: {
        /** A parameter. Optional */
        everything?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<LastUpdatedData[], any>({
        path: `/reference/last-updated`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReferenceControllerQuerySpeedReport
     * @request GET:/reference/query-speed-report
     */
    referenceControllerQuerySpeedReport: (params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/reference/query-speed-report`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReferenceControllerUserList
     * @request GET:/reference/user-list
     */
    referenceControllerUserList: (params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/reference/user-list`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReferenceControllerSharedPresetQueryList
     * @request GET:/reference/shared-preset-list
     */
    referenceControllerSharedPresetQueryList: (params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/reference/shared-preset-list`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReferenceControllerUserAdminLinks
     * @request GET:/reference/user-admin-links
     */
    referenceControllerUserAdminLinks: (params: RequestParams = {}) =>
      this.request<AdminLink[], any>({
        path: `/reference/user-admin-links`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  report = {
    /**
     * No description
     *
     * @name ReportControllerGetResource
     * @request GET:/report/get-resource
     */
    reportControllerGetResource: (params: RequestParams = {}) =>
      this.request<ReportResource, any>({
        path: `/report/get-resource`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportControllerTest
     * @request POST:/report/test
     */
    reportControllerTest: (data: QueryRequest, params: RequestParams = {}) =>
      this.request<QueryResult, any>({
        path: `/report/test`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportControllerQuery
     * @request POST:/report/query
     */
    reportControllerQuery: (data: QueryRequest, params: RequestParams = {}) =>
      this.request<QueryResult, any>({
        path: `/report/query`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportControllerPresetQueryById
     * @request GET:/report/preset-query/{id}
     */
    reportControllerPresetQueryById: (id: number, params: RequestParams = {}) =>
      this.request<QueryResult, any>({
        path: `/report/preset-query/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportControllerQueryTotal
     * @request POST:/report/query-total
     */
    reportControllerQueryTotal: (data: QueryRequest, params: RequestParams = {}) =>
      this.request<QueryTotalResult, any>({
        path: `/report/query-total`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportControllerQuerySql
     * @request POST:/report/query-sql
     */
    reportControllerQuerySql: (data: QueryRequest, params: RequestParams = {}) =>
      this.request<QuerySqlResult, any>({
        path: `/report/query-sql`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportControllerDownloadExcel
     * @request POST:/report/download-excel
     */
    reportControllerDownloadExcel: (data: QueryRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/report/download-excel`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportControllerDownloadCsv
     * @request POST:/report/download-csv
     */
    reportControllerDownloadCsv: (data: QueryRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/report/download-csv`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportControllerTerminationReport
     * @request POST:/report/termination-report
     */
    reportControllerTerminationReport: (data: TerminationReportRequest, params: RequestParams = {}) =>
      this.request<TerminationReport, any>({
        path: `/report/termination-report`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FinanceControllerGetTableFirstAndLastDate
     * @request GET:/report/finance/get-table-first-and-last-date/{dbName}/{tableName}
     */
    financeControllerGetTableFirstAndLastDate: (dbName: string, tableName: string, params: RequestParams = {}) =>
      this.request<DateInterval, any>({
        path: `/report/finance/get-table-first-and-last-date/${dbName}/${tableName}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FinanceControllerGetTableDateIntervals
     * @request GET:/report/finance/get-table-date-intervals/{dbName}/{tableName}
     */
    financeControllerGetTableDateIntervals: (dbName: string, tableName: string, params: RequestParams = {}) =>
      this.request<DateIntervalResults, any>({
        path: `/report/finance/get-table-date-intervals/${dbName}/${tableName}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FinanceControllerClaimDetailAllPreview
     * @request POST:/report/finance/claim-detail-all-preview
     */
    financeControllerClaimDetailAllPreview: (data: AllClaimDetailReportRequest, params: RequestParams = {}) =>
      this.request<FinanceReport, any>({
        path: `/report/finance/claim-detail-all-preview`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FinanceControllerGetReportDates
     * @request GET:/report/finance/get-report-dates
     */
    financeControllerGetReportDates: (params: RequestParams = {}) =>
      this.request<ReportIntervalDates[], any>({
        path: `/report/finance/get-report-dates`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FinanceControllerDownloadExcelFileFromS3
     * @request GET:/report/finance/download-report/{reportRunId}
     */
    financeControllerDownloadExcelFileFromS3: (reportRunId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/report/finance/download-report/${reportRunId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name FinanceControllerGenerateReport
     * @request POST:/report/finance/generate-report/{startDate}/{endDate}
     */
    financeControllerGenerateReport: (
      startDate: string,
      endDate: string,
      data: GenerateReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/report/finance/generate-report/${startDate}/${endDate}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  presets = {
    /**
     * No description
     *
     * @name PresetsControllerFindAll
     * @request GET:/presets
     */
    presetsControllerFindAll: (
      query: {
        isPublic: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<PresetListDto[], any>({
        path: `/presets`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PresetsControllerCreate
     * @request POST:/presets
     */
    presetsControllerCreate: (data: PresetDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/presets`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name PresetsControllerUpdate
     * @request PUT:/presets
     */
    presetsControllerUpdate: (data: PresetDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/presets`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name PresetsControllerFindOne
     * @request GET:/presets/{id}
     */
    presetsControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<PresetDto, any>({
        path: `/presets/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PresetsControllerRemove
     * @request DELETE:/presets/{id}
     */
    presetsControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/presets/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name PresetsControllerReorder
     * @request POST:/presets/reorder
     */
    presetsControllerReorder: (data: PresetReorderDto[], params: RequestParams = {}) =>
      this.request<PresetListDto[], any>({
        path: `/presets/reorder`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @name UsersControllerSaveUser
     * @request GET:/users/save-user
     */
    usersControllerSaveUser: (params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/users/save-user`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerSetting
     * @request GET:/users/setting
     */
    usersControllerSetting: (params: RequestParams = {}) =>
      this.request<UserSettingDto, any>({
        path: `/users/setting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerSaveNavWidth
     * @request GET:/users/save-nav-width
     */
    usersControllerSaveNavWidth: (
      query: {
        value: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/users/save-nav-width`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerSavePageSize
     * @request GET:/users/save-page-size
     */
    usersControllerSavePageSize: (
      query: {
        value: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/users/save-page-size`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerSaveNavPinned
     * @request GET:/users/save-nav-pinned
     */
    usersControllerSaveNavPinned: (
      query: {
        value: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/users/save-nav-pinned`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
}
