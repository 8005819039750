import { Box, Collapse, IconButton, Stack, Tab, TabContext, TabList, Tooltip } from "components";
import { useTheme } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { getSpacing } from "theme";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import { SelectedTab } from "./SelectedTab";
import { SelectTab } from "./SelectTab";
import { NavigationMenu } from "./NavigationMenu/NavigationMenu";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import PushPinIcon from "@mui/icons-material/PushPin";
import { setUserSettingNavPinned } from "redux/reducers/userSettingSlice";
import apiService from "services/apiService";
import styled from "@emotion/styled";
import { ReportContext } from "../ReportContextProvider";
import { getArrayFromTableTree } from "../utils/table-tree-utils";

export const NavigationBar = (props: { open: boolean; setOpen: (open: boolean) => void; }) => {
  const NAV_TABS = {
    TABLES: "Tables" as const,
    SELECTED: "Selected" as const,
  };

  type NavTab = (typeof NAV_TABS)[keyof typeof NAV_TABS];

  const dispatch = useDispatch();
  const reportContext = useContext(ReportContext);
  const navPinned = useSelector((state: RootState) => state.userSetting.navPinned);
  const navWidth = useSelector((state: RootState) => state.userSetting.navWidth);

  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState<NavTab>(NAV_TABS.TABLES);
  const [useQueryBuilder, setUseQueryBuilder] = useState<boolean>(false); // TODO: move this later

  //const menuWidth = !useQueryBuilder ? "250px" : "400px";
  const widthRef = useRef<number>(navWidth);
  const collapseWidth = 40;
  const MIN_WIDTH = 150;
  const MAX_WIDTH = 500;
  const isResizingRef = useRef(false);
  const prevX = useRef<number>(0);

  const open = navPinned ? true : props.open;

  useEffect(() => {
    console.log(navWidth)
    widthRef.current = navWidth;
  }, [navWidth]);

  const Resizer = styled("div")({
    cursor: "ew-resize",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: "5px",
    zIndex: 1, // To ensure it sits above the content
    "&:hover": {
      background: "rgba(0, 0, 0, 0.1)",
    },
  });

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    isResizingRef.current = true;
    prevX.current = event.clientX;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.body.style.userSelect = "none"; // Prevent text selection while dragging
  };

  const handleMouseMove = (event: MouseEvent) => {
    console.log("handleMouseMove");
    if (!isResizingRef) return;

    const newX = event.clientX;
    const diff = newX - prevX.current;

    const newWidth = Math.max(MIN_WIDTH, Math.min(MAX_WIDTH, widthRef.current + diff));

    widthRef.current = newWidth;
    prevX.current = newX;
  };

  const handleMouseUp = () => {
    isResizingRef.current = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    document.body.style.userSelect = ""; // Restore text selection behavior
    apiService.getApi().users.usersControllerSaveNavWidth({
      value: widthRef.current,
    });
  };

  return (
    <Collapse orientation="horizontal" in={open} sx={{ height: "100%" }} collapsedSize={collapseWidth}>
      <Stack
        width={widthRef.current}
        height="100%"
        className="stack"
        borderRight={`1px solid ${theme.palette.border.main}`}
      >
        <Resizer onMouseDown={handleMouseDown} />

        {!open && (
          <Stack
            width={collapseWidth}
            onClick={() => props.setOpen(true)}
            sx={{
              height: "100%",
              "&:hover": {
                cursor: "pointer",
                bgcolor: theme.palette.background.default,
              },
            }}
          >
            <Stack direction="row" justifyContent="flex-end" py={getSpacing("sm")}>
              <IconButton>
                <MenuIcon />
              </IconButton>
            </Stack>
          </Stack>
        )}

        {open && (
          <>
            {!useQueryBuilder ? (
              <TabContext value={currentTab}>
                <Stack direction="row" justifyContent="space-between" px={getSpacing("sm")} py={getSpacing("sm")}>
                  <NavigationMenu useQueryBuilder={useQueryBuilder} setUseQueryBuilder={setUseQueryBuilder} />

                  <Stack direction="row">
                    <Tooltip title="If pinned, this menu will stay in fixed in place when running report.">
                      <IconButton
                        color={navPinned ? "success" : "default"}
                        onClick={() => {
                          const setValue: boolean = !navPinned;
                          dispatch(setUserSettingNavPinned(setValue));
                          apiService.getApi().users.usersControllerSaveNavPinned({
                            value: setValue,
                          });
                        }}
                      >
                        <PushPinIcon />
                      </IconButton>
                    </Tooltip>

                    {!navPinned && (
                      <IconButton onClick={() => props.setOpen(false)}>
                        <MenuOpenIcon />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>

                <TabList
                  sx={{ width: "100%", borderBottom: "1px solid #e8e8e8" }}
                  onChange={(e, value: NavTab) => setCurrentTab(value)}
                  variant="fullWidth"
                >
                  <Tab
                    key={NAV_TABS.TABLES}
                    sx={{ width: "33%" }}
                    label={
                      <Stack direction="row" spacing="sm" alignItems="center">
                        {NAV_TABS.TABLES}
                      </Stack>
                    }
                    value={NAV_TABS.TABLES}
                  />

                  <Tab
                    key={NAV_TABS.SELECTED}
                    sx={{ width: "33%" }}
                    label={
                      <Stack direction="row" spacing="sm" alignItems="center">
                        {NAV_TABS.SELECTED}

                        {getArrayFromTableTree(reportContext.queryRequest.tableTree).length > 0 &&
                          `(${getArrayFromTableTree(reportContext.queryRequest.tableTree).length})`}
                      </Stack>
                    }
                    value={NAV_TABS.SELECTED}
                  />
                </TabList>

                <Box hidden={currentTab !== "Tables"} position="relative" height="100%" overflow="auto">
                  <SelectTab />
                </Box>

                <Box hidden={currentTab !== "Selected"} position="relative" height="100%" overflow="auto">
                  <SelectedTab />
                </Box>
              </TabContext>
            ) : (
              <Box>Placeholder</Box>
              // <QueryBuilder queryRequest={reportContext.queryRequest} setQueryRequest={reportContext.setQueryRequest} />
            )}
          </>
        )}
      </Stack>
    </Collapse>
  );
};
