import { Box, Stack, Table, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import apiService from "services/apiService";


export function RecentUsers() {
    const [userListData, setUserListData] = useState<any[]>([]);


    useEffect(() => {

        apiService
        .getApi()
        .reference
        .referenceControllerUserList()
        .then((response: any) => {
            setUserListData(response.data);
        });
        
    }, []);

    return <Box width={"98%"}>
                <Stack direction="column">
                    <Box><Typography fontWeight="bold" fontSize="1.1rem" marginBottom="0.5em">Recent Users</Typography></Box>
                    <Box>
                        <Table width="100%">
                            <thead>
                                <tr>
                                    <th style={{textAlign: "left"}}>User</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userListData.map((row, index) => {
                                        return <tr key={index}>
                                            <td>{row.Email}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </Box>
                </Stack>
            </Box>
}