import { DbtTable } from "api/Api";
import { DataSource } from "models/DataSource";
import moment from "moment";

export function padNumber(num: number, places: number = 2): string {
  let results = `${num}`;
  let i = results.length;
  while (i < places) {
    results = "0" + results;
    i++;
  }
  return results;
}

export function getFileSafeDateTime(dateTime = new Date()): string {
  return `${dateTime.getFullYear()}-${padNumber(dateTime.getMonth() + 1)}-${padNumber(dateTime.getDate())} ${padNumber(
    dateTime.getHours()
  )}:${padNumber(dateTime.getMinutes())}`;
}

export function getUIDateFormat(dateTime: Date | string): string {
  if (typeof dateTime === "string") {
    dateTime = moment(dateTime).toDate();
  }
  return `${padNumber(dateTime.getMonth() + 1)}/${padNumber(dateTime.getDate())}/${dateTime.getFullYear()}`;
}

export function getQuerySafeDate(dateTime = new Date()): string {
  return `${dateTime.getFullYear()}-${padNumber(dateTime.getMonth() + 1)}-${padNumber(dateTime.getDate())}`;
}

export function getFileSafeDate(dateTime = new Date()): string {
  return `${dateTime.getFullYear()}-${padNumber(dateTime.getMonth() + 1)}-${padNumber(dateTime.getDate())}`;
}

export function getTableDisplayNameByName(dbtTables: DbtTable[] = [], name: string) {
  const foundTable = dbtTables.find((t) => t.name.toLowerCase() === name.toLowerCase());
  return foundTable ? foundTable.displayName : name;
}

export function getSourceDisplayNameByName(sources: DataSource[], name: string) {
  return sources.find((s) => s.name === name)?.displayName ?? name;
}

export function rangeArrayGenerator(start, stop, step) {
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
}

export function queryDateFormatter(d: Date): string {
  return `${d.getFullYear()}-${zeroPad(d.getMonth() + 1)}-${zeroPad(d.getDate())}`;
}

export function zeroPad(n: number): string {
  return n.toString().padStart(2, "0");
}

export function decodeJWT(token) {
  const base64Url = token.split(".")[1]; // Extract the payload part of the token
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Replace characters according to base64url specifications
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((char) => "%" + ("00" + char.charCodeAt(0).toString(16)).slice(-2)) // Decode base64 to UTF-8
      .join("")
  );

  return JSON.parse(jsonPayload); // Parse the JSON payload
}
