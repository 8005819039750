import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControl, FormLabel, Input, Stack } from "@mui/material";
import { EmailableReport } from 'api/Api';
import { useEmailableReports } from "hooks/email/useEmailableReports";
import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';

export function ReportPicker({reportId, reportName, onChange}:{reportId?: number, reportName: string, onChange: (report_id: number | undefined) => void}) {
    
    const [emailableReports, emailableReportsLoading] = useEmailableReports();
    const [filteredEmailableReports, setFilteredEmailableReports] = useState<EmailableReport[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    useEffect(() => {
        
        if(emailableReportsLoading){ return; }
        // emergency room, or emailable reports
        const er = emailableReports.filter(report => (
            searchValue === "" ||
            report?.reportName?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        ));

        setFilteredEmailableReports(sortBy(er, (r)=> r.id !== reportId));
    },[emailableReports, emailableReportsLoading, filteredEmailableReports, reportId, searchValue])
    if(emailableReportsLoading) return <Box>Loading...</Box>

    return <Stack direction="column" spacing="4" padding="4" margin="4">
            <Box>
                <label>Report to Send: {reportName}</label>
            </Box>
            <Box>
                <FormControl fullWidth>
                    <FormLabel>
                        <SearchIcon fontSize='small' style={{width: '2rem'}} />
                        <Input name="search" value={searchValue}
                        style={{width: "calc(100% - 2rem)"}}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }} 
                        placeholder="Search by report name"
                        />
                    </FormLabel>
                </FormControl>
            </Box>
            <Box style={{maxHeight: "20rem", overflow: "auto"}}>
                <ul style={{listStyle: "none"}}>
                    {filteredEmailableReports.length === 0 && <li>No reports found</li>}
                    {filteredEmailableReports.map((report) => (
                        <li key={report.id}>
                            <label>
                                <input type="radio" name="report_id" value={report.id} checked={reportId === report.id} onChange={() => onChange(report.id)} />
                                {report.reportName}
                            </label>
                        </li>
                    ))}
                </ul>
            </Box>
        </Stack>
}