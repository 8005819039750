import { Outlet, RouteObject } from "react-router-dom";
import { TaskSchedulerDashboard } from "./TaskSchedulerDashboard/TaskSchedulerDashboard";
export const taskSchedulerPageRoute: RouteObject = {
    path: 'task-scheduler',
      handle: 'Task Scheduler',
      element: <TaskScheduler />,
      children: [
        {
          index: true,
          path: '',
          handle: 'Dashboard',
          element: <TaskSchedulerDashboard />,
        },
    ]
};

export function TaskScheduler(){
  return (
    <><Outlet /></>
);
}