import React from 'react';
import { Stack as MuiStack, StackProps as MuiStackProps } from '@mui/material';
import { Spacing, getSpacing } from 'theme';

type StackProps = Omit<MuiStackProps, 'spacing'> & {
  spacing?: Spacing; // "lg" for Cards, "md" for general, "sm" for Inputs, "xs" for buttons and chips
};

const Stack = React.forwardRef<HTMLDivElement, StackProps>(
  ({ children, spacing, ...rest }, ref) => {
    return (
      <MuiStack ref={ref} spacing={getSpacing(spacing)} {...rest}>
        {children}
      </MuiStack>
    );
  }
);

export { Stack };
