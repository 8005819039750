import { EmailableReport } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

// yeah, yeah, this is not quite right, but it's working, and I've got a timeline.
export function useDashboardReports(): [EmailableReport[] | undefined, boolean] {
  const [dashboardReports, setDashboardReports] = useState<EmailableReport[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loading || dashboardReports) {
      return;
    }
    setLoading(true);
    apiService
      .getApi()
      .admin.emailReportControllerGetLandingReports()
      .then((response) => {
        setDashboardReports(response.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log("unable to use dashboard reports", e);
      });
  }, [dashboardReports, loading]);

  return [dashboardReports, loading];
}
