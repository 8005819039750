import { Box, Stack, Typography } from "@mui/material";
import { Employee, Facility } from "api/Api";
import { useEmployee } from "hooks/useEmployee";
import { useLocationEncountersForDate } from "hooks/useLocationEncountersForDate";
import { useParams } from "react-router-dom";
import { FAILURE_RESPONSE_OPTION, PermsCheck, VALID_ROLES } from "routes/PermsCheck";

export function MedicalAssistantReport() {
    const params = useParams();
    const email = params?.email;
    const date = params?.date;
    const [employee, loadingEmployee] = useEmployee(email);
    
    if(!email) {return (<Box>Invalid Email</Box>);}
    if(!date) {return (<Box>Invalid Date</Box>);}
    
    if(loadingEmployee || !employee) return (<Box>Loading... (Employee)</Box>);

    return (<PermsCheck 
                requiredRoles={[VALID_ROLES.EXECUTIVE, VALID_ROLES.DEVS, VALID_ROLES.MEDICAL_ASSISTANT]} 
                failureResponse={FAILURE_RESPONSE_OPTION.NONE} 
                email={email}>
                    <MedicalAssistantDashboard employee={employee} date={date} />
                </PermsCheck>);
}

export function MedicalAssistantDashboard({employee, date}: {employee: Employee; date: string;}) {
    const facilityId = employee.facility_id;

    return <Stack direction="column">
                        <Box>
                            <Typography fontWeight={"bold"}>Medical Assistant Report</Typography>
                        </Box>
                        <Stack direction="row">
                            <Box>
                                Encounter Type Pie Chart
                            </Box>
                            <Box>
                                Scheduled Encounters for the Facility
                                {facilityId && <ScheduledFacilityEncounters facilityId={facilityId} date={date} />}
                            </Box>
                        </Stack>
                    </Stack>
}

export function ScheduledFacilityEncounters({facilityId, date}: {facilityId: number; date: string;}) {
    const [facility, facilityIsLoading] = useLocationEncountersForDate(facilityId, date);
    if(facilityIsLoading){
        return <Box>(MA) Loading...</Box>;
    }
    
    return <Box>
        {
            facility?.staff?.map((staff) => {
                return <Box key={staff.id}>
                        <Typography>{staff.last_name}, {staff.first_name}</Typography>
                        <Typography>{staff?.encounters?.length}</Typography>
                        <Stack direction="column">
                            <ul>
                                {
                                    staff?.encounters?.map((encounter) => {
                                        return <li key={encounter.id}>
                                            <Typography>{encounter.id} {encounter.datetime} {encounter.type}</Typography>
                                        </li>
                                    })
                                }
                            </ul>
                        </Stack>
                    </Box>
                })
        }
            </Box>;
}