import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { DatePickerProps as MuiDatePickerProps } from "@mui/x-date-pickers/DatePicker";

export type DatePickerProps = Omit<MuiDatePickerProps<Date, Date>, "value" | "onChange" | "renderInput"> & {
  label?: string;
  value?: any;
  onChange?: (date: any | undefined, keyboardInputValue?: string) => void;
  errors?: string[];
  required?: boolean;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  autoFocus?: boolean;
};

// export class UTCUtils extends MomentUtils {
//   format = (value, formatString) => {
//     return moment(value).utc().format(formatString);
//   };
// }

export const DatePicker = ({
  label,
  value,
  onChange,
  size = "medium",
  errors,
  required,
  disabled,
  autoFocus,
  ...rest
}: DatePickerProps) => {
  const errorMsg = errors && errors?.length > 0 ? errors[0] : null;
  const hasError = errorMsg ? true : false;

  const handleChange = (dateValue) => {
    onChange?.(dateValue ? dateValue.format("YYYY-MM-DD") : null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        label={label}
        value={value || null} // || null to prevent auto prefill today's date when adding a new listItem: why?
        onChange={handleChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            required={required}
            variant={"outlined"}
            error={params.error ? params.error : hasError}
            helperText={errorMsg}
            size={size}
            autoComplete="off"
            autoFocus={autoFocus}
          />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
};
