import { Grid as MuiGrid, GridProps as MuiGridProps } from "@mui/material";
import { Spacing, getSpacing } from "theme";

type GridProps = Omit<MuiGridProps, "spacing"> & {
  spacing?: Spacing;
};

export const Grid = ({ children, spacing, ...rest }: GridProps) => {
  return (
    <MuiGrid spacing={getSpacing(spacing)} {...rest}>
      {children}
    </MuiGrid>
  );
};
