import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function usePotentialRolls() {
  const [rolls, setRolls] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    apiService
      .getApi()
      .admin.emailReportControllerGetPotentialRolls()
      .then((response) => {
        setRolls(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);
  return [rolls, loading];
}
