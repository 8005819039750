import { Chip as MuiChip, ChipProps as MuiChipProps } from "@mui/material";

export type ChipProps = MuiChipProps & {
  fullWidthLabel?: boolean;
};

export const Chip = ({ fullWidthLabel, sx, ...rest }: ChipProps) => {
  return (
    <MuiChip
      sx={{
        ...sx,
        "& .MuiChip-label": {
          width: fullWidthLabel ? "100%" : "unset",
        },
      }}
      {...rest}
    />
  );
};
