import { Box, Button, Container, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useReportSegments } from "hooks/email/segments/useReportSegments";
import { Link, Outlet, RouteObject, useNavigate } from "react-router-dom";
import { ReportSegment } from "./ReportSegment";

export const segmentManagementPageRoute: RouteObject = {
    path: 'segments',
      handle: 'Report Segments',
      element: <Outlet />,
      children: [
        {
            index: true,
            path: '',
            handle: 'Report Segments',
            element: <ReportSegments />,
          },
        {
            index: true,
            path: 'segment/:id',
            handle: 'Segment',
            element: <ReportSegment />
        }
      ]
    }

export function ReportSegments() {
    const [reportSegments, loading] = useReportSegments();
    const navigate = useNavigate();
    if(loading) {
        return <div>Loading...</div>;
    }

    return (<Container>
            <Stack>
                <Stack direction="row" spacing={2} width="100%">
                    <Box width="75%">
                        <Typography variant="h6">Report Segments</Typography>
                    </Box>
                    <Box  style={{textAlign: "right", width: "25%"}}>
                        <Button onClick={()=>{
                            navigate('segment/0');
                        }}>Create Report Segment</Button>
                    </Box>
                </Stack>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell width="15%">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportSegments.map((segment) => {
                            return (
                                <TableRow key={segment.id}>
                                    <TableCell>
                                        <Link to={`segment/${segment.id}`}>{segment.name}</Link>
                                    </TableCell>
                                    <TableCell>
                                        {segment.type === 0 ? 'Email' : 'Dashboard'}
                                    </TableCell>
                                    <TableCell width="15%">
                                        <Link onClick={(e)=>{
                                            e.preventDefault();
                                            console.log('todo!')
                                        }} to={`segment/delete/${segment.id}`}>Delete</Link>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Stack>
        </Container>);
}