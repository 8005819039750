import { EmailableReport } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useCustomReport(
  reportId: string | number | undefined,
  userId: string | number | undefined,
  date: string | undefined
): [EmailableReport | undefined, boolean] {
  const [report, setCustomReport] = useState<EmailableReport | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (reportId === undefined || userId === undefined || date === undefined) {
      return;
    }
    if (loading || report) {
      return;
    }
    setLoading(true);
    apiService
      .getApi()
      .admin.emailReportControllerGetEmailableReport(typeof reportId === "string" ? parseInt(reportId) : reportId)
      .then((response) => {
        setCustomReport(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [report, loading]);

  return [report, loading];
}
