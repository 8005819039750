import InvalidIcon from "@mui/icons-material/Block";
import { Tooltip } from "@mui/material";
import { Filter as FilterModel } from "api/Api";
import { Checkbox, IconButton, Stack, Typography } from "components";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { ReactNode, useContext } from "react";

export const FilterStatus = (props: { filter: FilterModel; setActive: (value: boolean) => void }) => {
  const reportContext = useContext(ReportContext);
  const isValid = reportContext.validateFilter(props.filter);

  if (isValid) {
    return (
      <Tooltip
        title={
          props.filter.active
            ? "Check off to unapply this filter to the query."
            : "Check on to apply this filter to the query."
        }
      >
        <Checkbox checked={props.filter.active} onChange={(e) => props.setActive(e.target.checked)} />
      </Tooltip>
    );
  } else {
    const errorMessages = reportContext.validateFilter(props.filter, { errorMessages: true });
    const errorNode: ReactNode = (
      <Stack spacing="sm">
        <Typography variant="inherit">Invalid Filter: this filter will not be applied</Typography>

        {errorMessages?.length > 0 && (
          <Stack>
            {errorMessages.map((x) => (
              <Typography variant="inherit">- {x}</Typography>
            ))}
          </Stack>
        )}
      </Stack>
    );

    return (
      <Tooltip title={errorNode}>
        <IconButton>
          <InvalidIcon color="error" />
        </IconButton>
      </Tooltip>
    );
  }
};
